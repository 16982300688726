import axios from 'axios';

// 创建axios实例
const instance = axios.create({
    baseURL: 'https://bestucollege.com/api/',
    headers: {
        'Content-Type': 'application/json',
    }
});

// 可以在这里添加请求拦截器
instance.interceptors.request.use(config => {
    // Do something before request is sent
    return config;
}, error => {
    // Do something with request error
    return Promise.reject(error);
});

// 可以在这里添加响应拦截器
instance.interceptors.response.use(response => {
    // Do something with response data
    return response;
}, error => {
    // Do something with response error
    return Promise.reject(error);
});

export default instance;
