import axios from '../index';

export const get_preferences = async () => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    };
    try {
        const response = await axios.get('profile/preferences', config);
        console.log('successfully get the preferences!!');
        console.log(response.data);
        return response.data;

    } catch (error) {
        return null;
    }
}

export const add_preferences = async (data) => {
    console.log('check data', data);
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
        }
    };
    try {
        const response = await axios.post('profile/preferences', data, config);
        window.location.href = '/edit-profile';
    } catch (error) {
        console.error('Error during add a preference:', error);
    }
}
