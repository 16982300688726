// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProseMirror:focus {
    outline: none;
}

.ProseMirror {
    word-break: keep-all;
    overflow-wrap: break-word;
}

.editor-content {
    font-size: 16.5px; /* Specify your desired font size here */
    font-family: 'Inter'
}

mark {
    background-color: #FDEBD0;
    border-radius: 0.25em;
    -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
    padding: 0.125em 0;
}`, "",{"version":3,"sources":["webpack://./src/editor/components/TipTap.css"],"names":[],"mappings":"AAGA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB,EAAE,wCAAwC;IAC3D;AACJ;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,mCAA2B;YAA3B,2BAA2B;IAC3B,kBAAkB;AACtB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');\n\n.ProseMirror:focus {\n    outline: none;\n}\n\n.ProseMirror {\n    word-break: keep-all;\n    overflow-wrap: break-word;\n}\n\n.editor-content {\n    font-size: 16.5px; /* Specify your desired font size here */\n    font-family: 'Inter'\n}\n\nmark {\n    background-color: #FDEBD0;\n    border-radius: 0.25em;\n    box-decoration-break: clone;\n    padding: 0.125em 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
