import React from "react";

export default function Header() {
  const logoText = "BestU";
  const titleText = "Equalize college application opportunities for all students";

  return (
    <div className = "header">
      <div className="flex flex-row justify-between gap-10 items-center w-full">
        <div className="text-2xl font-bold"> {logoText} </div>
        <div className="text-left text-sm "> {titleText} </div>
      </div>
    </div>
  );
}
