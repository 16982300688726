import React, { useState, useEffect } from "react";
import logo from '../../../icons/logo.png';

export default function BotMessage({ fetchMessage }) {
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    async function loadMessage() {
      const msg = await fetchMessage();
      setLoading(false);
      setMessage(msg);
    }
    loadMessage();
  }, [fetchMessage]);

  return (
    <div className="message-container">
      <div className="flex">
        <div className="flex-shrink-0 pt-4 pl-4">
          <img
            src={logo}
            alt="Bot Avatar"
            className="h-8 w-8 rounded-full"
          />
        </div>
        <div className="ml-2 min-w-[500px]">
          <div className="bot-name pl-1 pt-4 pb-1">BestU AI Assistant</div>
          <div className="bot-message">{isLoading ? "..." : message}</div>
        </div>
      </div>
    </div>
  );
}
