// Note: This is the Dashboard component
import SideBar from "../sidebar/LightSideBar"
import Billing from "./Billing"
import navigation from "../sidebar/Navigator"


export default function BillingWrapper() {
    for (var i = 0; i < navigation.length; i++) {
        console.log(navigation[i]["name"]);
        if (navigation[i]["name"] == "Billing") {
          navigation[i]["current"] = true;
        } else {
          navigation[i]["current"] = false;
        }
      }
    
    const main_content = (
        <main className="bg-white min-h-screen">
            <Billing/>
        </main>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation}/>
    )
}

