import React, { useState, useEffect } from 'react';
import '../EditProfile.css';
import edit_2 from '../../icons/profile/edit_2.png';
import { useNavigate } from 'react-router-dom';
import {add_extracurrcular, delete_extracurrcular, get_extracurrculars } from '../../request/profile/extracurriculars_api';

const Extracurriculars = () => {
    const navigate = useNavigate(); 
    const categories = ['Sport', 'Honor Society', 'Employment', 'Interest Club', 'Competition', 'Hobby'];
    const [extracurriculars, setExtracurriculars] = useState(
        categories.reduce((acc, cat) => ({ ...acc, [cat]: [] }), {})
    );
    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState({ name: '', category: categories[0], description: '' });
    const [isError, setIsError] = useState(false);  
    const [wordCount, setWordCount] = useState(0);
    const maxWords = 250;

    const handleTextChange = (e) => {
        const text = e.target.value;
        const words = text.split(/\s+/).filter(Boolean).length;
        if (words <= maxWords) {
            setFormValues((prev) => ({ ...prev, description: text }));
            setWordCount(words);
        }
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));
    };

    const handleEditClick = () => {
        navigate('/profile/detail-extracurriculars');  
    };

    const handleSubmission = async(event) => {
        event.preventDefault();
        const {name, category } = formValues;
        if (!name || !category) {
            setIsError(true);
            return;
        }
        const newExtracurricular = { name: name, description: formValues.description, category : category};
        setExtracurriculars((prev) => ({
            ...prev,
            [category]: [...prev[category], newExtracurricular]
        }));

        await add_extracurrcular(newExtracurricular);
        setIsError(false);
        closeModal();
    };

    // Helper function to handle pluralization
    const getPluralCategory = (cat) => {
        // Check if the category ends with 'y' to replace with 'ies', otherwise add 's'
        return cat.endsWith('y') ? `${cat.slice(0, -1)}ies` : `${cat}s`;
    };

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);


    useEffect(() => {
        const fetchData = async () => {
            const newData = await get_extracurrculars();
            if (newData && newData.extracurriculars) {
                const data = newData.extracurriculars;
                const categorizedData = categories.reduce((acc, cat) => ({ ...acc, [cat]: [] }), {});
                data.forEach(extracurricular => {
                    if (categorizedData[extracurricular.category]) {
                        categorizedData[extracurricular.category].push(extracurricular);
                    }
                });
                setExtracurriculars(categorizedData);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-col space-x-1 bg-cover bg-no-repeat bg-center rounded-[16px] shadow-md p-4 gap-4 session-header bg-[#FFFFFF]">
            <div className="flex flex-row justify-between items-center w-full"> 
                <div className="text-xl mx-4 font-semibold text=[#042F53] ">Extracurriculars</div>
                <div>
                    <button className="text-[#30394A] px-4 py-2 rounded text-sm" onClick={handleEditClick}>
                        Edit
                        <img src={edit_2} alt="edit" className="w-4 h-4 inline-block ml-2 mb-1" />
                    </button>
                </div>
            </div>

            <div className="flex flex-row items-center px-2 gap-5 justify-items-stretch justify-between"> 
                <div className="flex flex-row gap-5 items-center">
                {Object.keys(extracurriculars).map((cat) => {
                    const categoryItems = extracurriculars[cat]; 
                    return (
                        categoryItems.length > 0 && (
                            <div key={cat} className="flex flex-row text-xs items-center profile-text font-bold">
                                <p className = 'text-[#78BEE3] font-[700] text-[25px]'>{categoryItems.length}</p>
                                {/* handle pluralization   */}
                                {categoryItems.length > 1 ? getPluralCategory(cat) : cat}
                            </div>
                        )
                    );
                })}
                </div>  

                <button
                    onClick={openModal}
                    className="text-sm border border-[#042F53] rounded-md px-3 text-[#042F53]">
                    + Add 
                </button>
            </div>

            {isOpen && (
                <Modal 
                    categories={categories}
                    formValues={formValues}
                    handleFormChange={handleFormChange}
                    handleTextChange={handleTextChange}
                    handleSubmission={handleSubmission}
                    wordCount={wordCount}
                    maxWords={maxWords}
                    isError={isError}
                    closeModal={closeModal}
                />
            )}
        </div>
    );
};

const Modal = ({ 
    categories, 
    formValues, 
    handleFormChange, 
    handleTextChange, 
    handleSubmission, 
    wordCount, 
    maxWords, 
    isError, 
    closeModal 
}) => (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Add Extracurricular</h2>
                <div 
                    onClick={closeModal} 
                    className="text-2xl cursor-pointer"
                >
                    &times;
                </div>
            </div>
            <form onSubmit={handleSubmission}>
                <div className="flex flex-col gap-6 mb-4">
                    <div>
                        <label htmlFor="activity-name" className="block text-sm font-medium text-gray-700">Activity Name*</label>
                        <input 
                            type="text" 
                            required
                            id="activity-name" 
                            name="name"  
                            placeholder="Type to select..."
                            onChange={handleFormChange}
                            className="mt-1 block w-full p-2 border border-gray-300 rounded" 
                        />
                    </div>

                    <div>
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700">Category*</label>
                        <select
                            id="category"
                            name="category"
                            onChange={handleFormChange}
                            required
                            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value="">Select...</option>
                            {categories.map((category) => (
                                <option key={category} value={category}>{category}</option>
                            ))}
                        </select>
                    </div>
                    
                    <div>
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description (optional)</label>
                        <textarea
                            id="description"
                            name="description"
                            rows="4"
                            className="block w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 resize-none overflow-auto"
                            placeholder="Summarize your role, highlighting specific achievements. Include quantifiable results (if possible) and skills developed."
                            onChange={handleTextChange}
                        />
                        <div className="flex justify-between items-center mt-2">
                            <div className="text-sm text-gray-500">Maximum {maxWords} words</div>
                            <div className={`text-sm ${wordCount > maxWords ? 'text-red-500' : 'text-gray-500'}`}>
                                {wordCount}/{maxWords} words
                            </div>
                        </div>
                    </div>
                </div>

                {isError && 
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md relative" role="alert">
                        <div className="block sm:inline">Please fill out the required fields.</div>
                    </div> 
                }

                <div className="flex justify-end">
                    <button 
                        className="bg-[#8FC1D6] text-white px-4 py-2 rounded-md hover:bg-[#8FC1D6] shadow-2xl"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
);

export default Extracurriculars;
