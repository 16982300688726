import SideBar from "../sidebar/LightSideBar";
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import navigation from "../sidebar/Navigator"
import Hints from "./components/Hints";
import Form from "./components/Forms";
import Popups from "./components/Popups";

export default function InstructionEditing() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const university = queryParams.get('university');
    const prompt = queryParams.get('prompt');
    const word_limit = queryParams.get('word_limit');

    const [mode, setMode] = useState("Fast Mode");
    const [open, setOpen] = useState(false);

    const editing_cost = 2;


    const handleClick = async () => {
        if (received - paid < editing_cost) {
            alert("Balance is not enough");
        } else {
            setOpen(true);
            console.log(mode);
        }
    }

    const [paid, setPaid] = useState(0);
    const [received, setReceived] = useState(0);


    const retrieveBalance = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch("https://bestucollege.com/api/billing/retrieve_balance", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
            });

            if (!response.ok) {
                throw new Error("HTTP request failed");
            }

            const result = await response.json();

            setPaid(result["paid"]);
            setReceived(result["received"]);

            console.log(result);

        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        retrieveBalance();
    }, []);

    const main_content = (
        <main className="bg-white min-h-screen">
            <Hints university={university} prompt={prompt} />
            <div class=" mx-auto px-4 bg-white">
                <div class="max-w-md mx-auto px-4 bg-white">
                    <Popups word_limit={word_limit} university={university} prompt={prompt} setError={setError} setIsLoading={setIsLoading} mode={mode} open={open} setOpen={setOpen} credit={received - paid - editing_cost} />
                    <Form setMode={setMode} />
                </div>
            </div>

            <button
                className="flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 "
                onClick={handleClick}
            >
                Next Step
            </button>
        </main>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation} />
    )
}
