import { PaperClipIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';

export default function EditingCard() {
    // retrieve username from local storage
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const uuid = queryParams.get('uuid'); // UUID

    const [essayData, setEssayData] = useState({
        "prompt": "",
        "university": "",
        "detailed_feedback": "",
        "summary_feedback": "",
        "grade": "",
        "dialogue": "",
        "new_draft": "",
    });
    
    function smartQuoteReplacement(str) {
        const regex = /(\w)'(\w)/g;
        return str.replace(regex, '$1$2');
    }


    function formatDataSections(dataString) {
        // Split the data string into individual objects
        const objects = dataString.split('}, {');
        
        // Initialize an array to hold formatted sections
        let formattedSections = [];
    
        objects.forEach(obj => {
            obj = smartQuoteReplacement(obj);
            let formattedObj = obj.replace(/'/g, '"');

            let title = formattedObj.match(/"title": "(.*?)"/)?.[1];
            let feedback = formattedObj.match(/"feedback": "(.*?)"/)?.[1];
            let editing = formattedObj.match(/"editing": "(.*?)"/)?.[1];
            let brainstormQuestions = formattedObj.match(/"brainstorm_questions": "(.*?)"/)?.[1];
            let quote = formattedObj.match(/"quote": "(.*?)"/)?.[1];
    
            let section = `**Title: ${title}**\n\nFeedback: ${feedback}\n\nEditing: ${editing}\n\nBrainstorm Questions: ${brainstormQuestions}\n\nQuote: ${quote}\n\n`;
            formattedSections.push(section);
        });
    
        // Join all sections into a single string
        return formattedSections.join("\n---\n");
    }
    

    function formatListToMarkdown(list) {
        const formatted_data = formatDataSections(list);
    
        console.log(formatted_data);
    
        return formatted_data;
    }


    const retrieveEssay = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const data = {
                "editing_id": uuid
            }
            const response = await fetch("https://bestucollege.com/api/history/retrieve_editing_details", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error("HTTP request failed");
            }

            const result = await response.json();
            console.log(result);
            setEssayData(result)
            // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        retrieveEssay();
    }, []);

    return (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-6 sm:px-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">{essayData.prompt}</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{essayData.university}</p>
            </div>
            <div className="border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900 sm:col-span-1">Detailed Feedback</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                            <ReactMarkdown>{formatListToMarkdown(essayData.detailed_feedback)}</ReactMarkdown>
                        </dd>
                    </div>

                    <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-900 sm:col-span-1">Draft</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                            <ReactMarkdown>{essayData.draft}</ReactMarkdown>
                        </dd>
                    </div>

                </dl>
            </div>
        </div>
    )
}