import React, { useState, useEffect } from 'react';
import '../EditProfile.css';
import edit_2 from '../../icons/profile/edit_2.png';
import { save_coursework, get_coursework } from '../../request/profile/courseWork_api';

const CourseWork = () => {
    // Use a single state object for form inputs
    const [savedValues, setSavedValues] = useState({
        honourNum: 0,
        collegeCourseNum: 0,
        apNum: 0,
        gpa: 0,
        gradingScale: '0-4' //default value
    });
    const [formValues, setFormValues] = useState(savedValues);   
    const [isOpen, setIsOpen] = useState(false);
    const [isError, setIsError] = useState(false); 
    const [selectedOption, setSelectedOption] = useState("0-4");

    const openModal = () => {
        setFormValues(savedValues);
        setIsOpen(true);
    };
    const closeModal = () => setIsOpen(false);

    // Handle grading system change
    const handleGradingSystemChange = (e) => {
        const newValue = e.target.value;
        if (newValue > 0) { 
            setSelectedOption(`0-${newValue}`);
            setFormValues((prevValues) => ({
                ...prevValues,
                ['gradingScale']: `0-${newValue}`
            }));
            setIsError(false);
        } else {
            setIsError(true);
        }
    };

    // Handle form field changes (for gpa, honourNum, courseNum, apNum)
    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value >= 0 ? parseFloat(value) : 0
        }));

        if (value < 0 || isNaN(value)) {
            setIsError(true);
        } else {
            setIsError(false);
        }
    };

    // Submit form
    const handleSubmission = async (event) => {
        event.preventDefault();
        if (!isError) {
            setSavedValues(formValues); 
            await save_coursework(formValues);
            closeModal();
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            const coursework = await get_coursework();
    
            // Access the nested 'course_work_summary' object properly
            if (coursework && coursework.course_work_summary) {
                const data = coursework.course_work_summary;
                const fetchedValues = {
                    honourNum: data.honorNum || 0,
                    collegeCourseNum: data.collegeCourseNum || 0,
                    apNum: data.apNum || 0,
                    gpa: data.gpa || 0,
                    gradingScale: data.gradingScale || '0-4'
                };

                setFormValues(fetchedValues);
                setSavedValues(fetchedValues);
                setSelectedOption(data.gradingScale || `0-4`);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-col space-x-1 bg-cover bg-no-repeat bg-center rounded-[16px] shadow-md p-4 gap-6 session-header bg-[#FFFFFF]">
            <div className="flex flex-row justify-between items-center w-full"> 
                <div className='text-xl mx-4 font-semibold text-[#042F53]'>Coursework</div>
                <div>             
                    <button 
                        onClick={openModal} 
                        className=" text-[#30394A] px-4 py-2 rounded text-sm"
                    >
                        Edit
                        <img src={edit_2} alt="edit" className="w-4 h-4 inline-block ml-2 mb-1" />
                    </button>
                    {isOpen && (
    
                        <Modal
                            formValues={formValues}
                            selectedOption={selectedOption}
                            handleFieldChange={handleFieldChange}
                            handleGradingSystemChange={handleGradingSystemChange}
                            handleSubmission={handleSubmission}
                            isError={isError}
                            closeModal={closeModal}
                        />
                    )}
                </div>
            </div>

            {/* Display GPA and other details */}
            <div className='flex flex-row gap-40 px-3'>
                <div className='space-y-2'>
                    <div className='font-semibold text-sm'>Unweighted GPA</div>
                    <div className='flex flex-row gap-3 text-xs items-center profile-text font-bold'>
                        <p className = 'flex flex-row gap-1'><div className = 'text-[#78BEE3] font-[700] text-[20px]'>{savedValues.gpa}</div> (Scale: {savedValues.gradingScale})</p>
                    </div>
                </div>
                <div className='space-y-2'>
                    <div className='font-semibold text-sm'>Courseworks</div>
                        <div className='flex flex-row gap-3 text-xs items-center profile-text font-bold'>
                            {savedValues.honourNum <= 1 ? <p className = 'flex flex-row gap-1'><div className = 'text-[#78BEE3] font-[700] text-[20px]'>{savedValues.honourNum}</div> Honor</p> : <p className = 'flex flex-row gap-1'><div className = 'text-[#78BEE3] font-[700] text-[20px]'>{savedValues.honourNum}</div> Honors</p>}
                            <p className = 'flex flex-row gap-1'><div className = 'text-[#78BEE3] font-[700] text-[20px]'>{savedValues.apNum}</div> AP</p>
                            {savedValues.collegeCourseNum <= 1 ? <p className = 'flex flex-row gap-1'><div className = 'text-[#78BEE3] font-[700] text-[20px]'>{savedValues.collegeCourseNum}</div> College Course</p> : <p className = 'flex flex-row gap-1'><div className = 'text-[#78BEE3] font-[700] text-[20px]'>{savedValues.collegeCourseNum}</div>College Courses</p>}
                        </div>
                </div>
            </div>
        </div>
    );
};

const Modal = ({ 
    formValues, 
    selectedOption, 
    handleFieldChange, 
    handleGradingSystemChange, 
    handleSubmission, 
    isError, 
    closeModal 
}) => {
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
            
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Edit Coursework</h2>
                    <div onClick={closeModal} className="text-2xl cursor-pointer">&times;</div>
                </div>

                <form onSubmit={handleSubmission}>
                    <div className="flex flex-col gap-6 mb-4">
                        <div>
                            <label htmlFor="grading-system" className="block text-sm font-medium text-gray-700">Grading System</label>
                            <input
                                type="number"
                                id="grading-system"
                                placeholder='Upper bound (e.g. 4)'
                                required
                                min="0"
                                value={selectedOption.split('-')[1]} 
                                onChange={handleGradingSystemChange} 
                                className="mt-1 block w-full p-2 border border-gray-300 rounded" 
                            />
                        </div>

                        <div>
                            <label htmlFor="unweighted-gpa" className="block text-sm font-medium text-gray-700">Unweighted GPA*</label>
                            <input 
                                type="number"
                                id="unweighted-gpa" 
                                name="gpa" 
                                required
                                min="0"
                                max={selectedOption.split('-')[1]} 
                                value={formValues.gpa} 
                                onChange={handleFieldChange} 
                                className="mt-1 block w-full p-2 border border-gray-300 rounded" 
                            />
                        </div>

                        <div>
                            <label htmlFor="honor-num" className="block text-sm font-medium text-gray-700">Honor Classes:</label>
                            <input 
                                type="number" 
                                required 
                                id="honor-num" 
                                name="honourNum" 
                                min="0"
                                value={formValues.honourNum} 
                                onChange={handleFieldChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded" 
                            />
                        </div>

                        <div>
                            <label htmlFor="ap-num" className="block text-sm font-medium text-gray-700">AP:</label>
                            <input 
                                type="number" 
                                required 
                                id="ap-num" 
                                name="apNum" 
                                min="0"
                                value={formValues.apNum} 
                                onChange={handleFieldChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded" 
                            />
                        </div>

                        <div>
                            <label htmlFor="course-num" className="block text-sm font-medium text-gray-700">College Courses</label>
                            <input 
                                type="number" 
                                required
                                id="course-num" 
                                name="collegeCourseNum" 
                                min="0"
                                value={formValues.collegeCourseNum} 
                                onChange={handleFieldChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded" 
                            />
                        </div>
                    </div>
                    {isError && 
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md relative" role="alert">
                            <div className="block sm:inline">Please fill out the required fields or input valid value.</div>
                        </div> 
                    }

                    <div className="flex justify-end">
                        <button 
                            className="bg-[#8FC1D6] text-white px-4 py-2 rounded-md hover:bg-[#8FC1D6] shadow-2xl"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CourseWork;



