import React, { useState } from 'react';

// The mapping of token types to numbers
const tokenMapping = {
    "BestYou Best Value Pack": 10,
    "BestYou Elite Pack": 20,
    "BestYou Supreme Pack": 30,
};

// The main component
function TokenGenerator() {
    const [tokenType, setTokenType] = useState('');
    const [tokenNum, setTokenNum] = useState('');
    const [result, setResult] = useState(null);
    const [error, setError] = useState('');

    // Handle the form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await generateToken(tokenType, tokenNum);
            setResult(response);
            setError('');
        } catch (err) {
            setError(err.message);
            setResult(null);
        }
    };

    // Function to render the result in a table format
    const renderResultTable = (result) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Token</th>
                        <th>Type</th>
                        <th>Value</th>
                        <th>Is Used</th>
                    </tr>
                </thead>
                <tbody>
                    {result.map((tokenInfo, index) => (
                        <tr key={index}>
                            <td>{tokenInfo.token}</td>
                            <td>{tokenInfo.type}</td>
                            <td>{tokenInfo.value}</td>
                            <td>{tokenInfo.is_used ? 'Yes' : 'No'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    Token Type:
                    <select value={tokenType} onChange={(e) => setTokenType(e.target.value)}>
                        <option value="">Select a Token Type</option>
                        {Object.keys(tokenMapping).map(key => (
                            <option key={key} value={key}>{key}</option>
                        ))}
                    </select>
                </label>
                <br />
                <label>
                    Number of Tokens:
                    <input type="number" value={tokenNum} onChange={(e) => setTokenNum(e.target.value)} />
                </label>
                <br />
                <button type="submit">Generate Token</button>
            </form>
            {result && renderResultTable(result)}
            {error && <div style={{ color: 'red' }}>{error}</div>}
        </div>
    );
}

// The function to generate a token
// The function to generate a token
export async function generateToken(tokenType, token_num) {
    try {
        // Retrieve the token from localStorage
        const bearerToken = localStorage.getItem('token');
        
        // Check if the tokenType is valid
        if (!tokenMapping[tokenType]) {
            throw new Error('Invalid token type');
        }

        // Prepare the request body
        const body = JSON.stringify({
            token_type: tokenType,
            token_num: parseInt(token_num)
        });

        // Set up the request options
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`
            },
            body: body
        };

        // Make the API call
        const response = await fetch('https://bestucollege.com/api/billing/generate_token', requestOptions);

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('API call failed with status ' + response.status);
        }

        // Parse the JSON response
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error generating token:', error);
        throw error;
    }
}

export default TokenGenerator;