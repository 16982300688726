// Note: This is the Dashboard component
import SideBar from "../sidebar/LightSideBar"
import SchoolList from "./components/SchoolList"
import navigation from "../sidebar/Navigator"
import CardContainer from "./components/CardContainer";
import HistorySession from "./components/HistorySession";
import backgroundImage from '../images/background.svg';
import addIcon from '../icons/add_icon.png';
import { useState } from "react";
import { options } from "./components/SchoolInfo";
import ComboList from "./components/ComboList";

export default function Dashboard() {
    // retrieve username from local storage
    const username = localStorage.getItem('username');

    const [current, setCurrent] = useState({
        "name": "All"
    });

    for (var i = 0; i < navigation.length; i++) {
        // console.log(navigation[i]["name"]);
        if (navigation[i]["name"] == "Dashboard") {
            navigation[i]["current"] = true;
        } else {
            navigation[i]["current"] = false;
        }
    }

    const ExpandList = () => {
        return (<div className="flex justify-end pr-6">
            <p className="text-sm text-blue-800 flex items-center" style={{ color: '#042F53' }}>
                {/* View All School
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.707a1 1 0 011.414 0L10 11.414l3.293-3.707a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg> */}
            </p>
        </div>)
    };

    const main_content = (
        <main style={{ backgroundImage: `url(${backgroundImage})` }} className="min-h-screen bg-cover bg-no-repeat bg-center">
            <div className="pt-5 pl-5 pb-3 pr-5 w-full">
                <CardContainer />
            </div>
            {/* School List */}
            <div className="pt-5 pl-6 pr-6">
                <div className="flex flex-row items-center justify-between">
                    <p className="text-xl font-semibold leading-10 text-gray-900">School List</p>
                    <p className="text-sm text-blue-800 flex items-center" style={{ color: '#042F53' }}>
                        {/* Add School */}
                        {/* <img src={addIcon} alt="Add" className="h-5 w-5 ml-2" /> */}
                    </p>
                </div>
            </div>

            <div className="p-4">{localStorage.getItem('token') && <SchoolList />}</div>

            {/* Recent Document Section */}

            <ExpandList />

            <div className="pt-5 pl-6 pr-6">
                <div className="flex flex-row items-center justify-between">
                    <p className="text-xl font-semibold leading-10 text-gray-900">Recent Document</p>


                    {/* Tabs Section */}
                    <div className="flex space-x-8">
                        <ComboList people={options} setNewVariable={setCurrent} />
                        <button className="text-blue-600 font-semibold border-b-2 border-blue-600">All</button>
                        <button className="text-gray-600 hover:text-gray-900">Edit</button>
                        <button className="text-gray-600 hover:text-gray-900">Outline</button>
                    </div>
                </div>
                <HistorySession />
            </div>


        </main>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation} />
    )
}