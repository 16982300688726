import React, { useState } from "react";
import "./Input.css";

export default function Input({ onSend, word_limit }) {
  const [text, setText] = useState("");

  console.log(word_limit)
  const maxChars = word_limit; // 设置最大字符数

  const handleInputChange = e => {
    const inputText = e.target.value;
    if (inputText.length <= maxChars) { // 检查字符数是否超过限制
      setText(inputText);
    }
  };

  const handleSend = e => {
    e.preventDefault();
    if (text.trim()) { // Only send if there's non-whitespace characters
      onSend(text);
      setText("");
    }
  };


  return (
    <div className="input">
      <form onSubmit={handleSend}>
        <textarea
          onChange={handleInputChange}
          value={text}
          placeholder="Enter your message here to interact with your best college consultant!"
          rows={2} // Start with one line
          style={{ resize: 'none', overflowY: 'auto' }} // Prevent resizing and allow vertical scroll
        />
        <button type="submit"> {/* Ensure the button submits the form */}
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 500 500"
          >
            <g>
              <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
            </g>
          </svg>
          <div className="char-count">
            {`${text.length}/${maxChars}`} {/* 显示当前字符数和最大字符数 */}
          </div>
        </button>
      </form>
    </div>
  );
}