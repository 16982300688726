// src/components/APSession.js
import React from 'react';
import InputField from '../InputField';

const APSession = ({ formData, handleInputChange, handleAddAPExam, handleRemoveAPExam }) => {
  return (
    <div className="mt-4 space-y-2">
      <h3 className="font-bold">AP Exams</h3>
      {formData.AP.map((apExam, index) => (
        <div key={index} className="grid grid-cols-5 gap-4 items-center">
          <div className="col-span-2">

            <InputField
              label="Course Name"
              name={`APCourseName_${index}`}
              value={apExam.courseName || ''}
              onChange={(e) => handleInputChange(e, 'AP', 'courseName', e.target.value, index)}
              placeholder="e.g. AP Calculus"
              type="text"
            />
          </div>
          <div className="col-span-2">
            <InputField
              label="Score"
              name={`APScore_${index}`}
              value={apExam.score || ''}
              onChange={(e) => handleInputChange(e, 'AP', 'score', e.target.value, index)}
              placeholder="Score"
              type="number"
            />
          </div>
          <button
            onClick={() => handleRemoveAPExam(index)}
            className="mt-6 col-span-1 bg-white text-[#8FC1D6] border-[#8FC1D6] border hover:bg-[#8FC1D6] hover:text-white rounded-md px-2 py-2"
          >
            Remove
          </button>
        </div>
      ))}
      <button onClick={handleAddAPExam} className="mt-2 text-blue-500 hover:text-blue-700">
        + Add AP Exam
      </button>
    </div>
  );
};

export default APSession;

