import React,  { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProfileStatus = ({rate}) =>{

    <div style={{ position: 'relative', width: '100px', height: '100px' }}>
        <svg width="0" height="0">
            <defs>
            <linearGradient id="gradientColors" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="rgba(100, 179, 244, 1)" />
                <stop offset="100%" stopColor="rgba(194, 229, 156, 1)" />
            </linearGradient>
            </defs>
        </svg>

        <CircularProgressbar
            value={rate}
            text={`${rate}%`}
            strokeWidth={10}
            styles={buildStyles({
            rotation: 0.25,
            textSize: '16px',
            pathTransitionDuration: 0.5,
            pathColor: `url(#gradientColors)`,
            trailColor: '#d6d6d6',
            })}
        />
    </div>

}

export default ProfileStatus;


