const API = {
  GetChatbotResponse: async (uuid, message) => {
    const data = {
      uuid: uuid,
      dialogue: message,
      bot_type: "Text"
    };

    const response = await fetch("https://bestucollege.com/api/college_bs/get_dialogue", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error("HTTP request failed");
    }

    const result = await response.json();
    return result["answer"];
  }
};

export default API;
