/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { useState, useEffect } from 'react'
import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon as XMarkIconMini } from '@heroicons/react/20/solid'
import BillingHistory from './BillingHistory'
import './Billing.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function BillingSummary(setIsToggled, credits_paid, credits_received) {
  return(
    <section
            aria-labelledby="summary-heading"
            className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
          >
            <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
              Billing Summary
            </h2>

            <dl className="mt-6 space-y-4">
              <div className="flex items-center justify-between">
                <dt className="text-sm text-gray-600">Credits Paid</dt>
                <dd className="text-sm font-medium text-gray-900">{credits_paid} Points</dd>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                <div className="flex items-center text-sm text-gray-600 relative">
                    Credits Received
                      <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500 icon-hover">
                          <span className="sr-only"></span>
                          <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                      </a>
                      <div className="message-box">
                         您可以通过微信购买package，可以用于购买BestYou服务
                      </div>
                  </div>
                <dd className="text-sm font-medium text-gray-900">{credits_received} Points</dd>
              </div>

              <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                <dt className="text-base font-medium text-gray-900">Balance total</dt>
                <dd className="text-base font-medium text-gray-900">{credits_received - credits_paid} Points</dd>
              </div>
            </dl>

            <div className="mt-6">
              <button
                type="submit"
                onClick={() => setIsToggled(true)}
                className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 border item-center hover:bg-grey-800"
              >
                Purchase More
              </button>
            </div>
          </section>
    )
}

function AddBalance(setIsToggled, setIsLoading, setError) {
  const addBalance = async (event) => {
      event.preventDefault();
      
      setIsLoading(true);
      setError(null);

      try {
        const data = {
          "token": document.getElementById("token_name").value
        }

        const response = await fetch("https://bestucollege.com/api/billing/add_balance", {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        });

        const result = await response.json();
        if (response.status == 201) {
          alert("Purchase successful! Thank you for getting" + result["type"] +"package \n" + "Your current balance: " + result["balance"] + " points");
          //setIsToggled(false);
        } else {
          alert(result["message"]);
        }
        
        // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
    } catch (err) {
        setError(err.message);
    } finally {
        setIsLoading(false);
    }
  };

  return(
         <div className="lg:col-span-5">
         <section
            aria-labelledby="summary-heading"
            className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
          >
            <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
              Add Balance
            </h2>

            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
              <div className="flex items-center text-sm text-gray-600 relative mr-5"> {/* Added right margin */}
                <span>Add Token</span>
                <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500 icon-hover">
                  <span className="sr-only"></span>
                  <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                <div className="message-box">
                   目前BestU为内测版本，请加BestYou小助手（微信ID：H-BLBFM）获取更多credits的机会）
                </div>
              </div>
              <input class="shadow appearance-none border border-blue-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="token_name" type="password" placeholder="******************"/> 
            </div>


            <div className="mt-6">
              <button
                type="submit"
                onClick={addBalance}
                className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 border transition duration-300"
              >
                Confirm Purchase
              </button>
            </div>
            <div className = 'mt-6'>
              目前BestU为内测版本，请加BestYou小助手（微信ID：H-BLBFM）获取更多credits的机会）
            </div>
          </section>

          </div>
    )
}

export default function Billing() {
  const [open, setOpen] = useState(false)
  const [isToggled, setIsToggled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paid, setPaid] = useState(0);
  const [received, setReceived] = useState(0);

  const retrieveBalance = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch("https://bestucollege.com/api/billing/retrieve_balance", {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
          throw new Error("HTTP request failed");
      }

      const result = await response.json();

      setPaid(result["paid"]);
      setReceived(result["received"]);

      console.log(result);

  } catch (err) {
      setError(err.message);
  } finally {
      setIsLoading(false);
  }
};

useEffect(() => {
  retrieveBalance();
}, []);

  return (
    <div className="bg-white">
      
      <main className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Billing</h1>

        <form className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">

          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <p className="font-semibold text-lg">Transaction History</p>
          </section>

          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <BillingHistory/>
          </section>
          
          {/* Billing summary */}
          {isToggled ? AddBalance(setIsToggled, setIsLoading, setError) : BillingSummary(setIsToggled, paid, received)}

         
        </form>

        
      </main>
    </div>
  )
}
