import {React, useEffect, useState} from 'react';
import InputField from '../InputField';
import MonthYearDropdown from './MonthYearDropdown';
import RadioButtonGroup from './RatioButtonGroup';

const EnglishLanguageSession = ({
  formData,
  setFormData,
  hasLanguageTest,
  setHasTest,
  hasLanguageTestName,
  setHasLanguageTestName,
  hasLanguageScore,
  handleHadLanguageScore,
  handleInputChange,
  setTotalLanguageScore,
  totalLanguageScore,
  languageTestSelectedDate,
  setLanguageTestSelectedDate,
  setTestScores,
  setIsError
}) => {

  const LanguageTestFields = {
    'Doulingo English Test': ['Literacy', 'Conversation', 'Comprehension', 'Production', 'TotalScore'],
    'TOEFL Paper': ['Listening', 'Reading', 'Writing', 'TotalScore'],
    'TOEFL iBT': ['Listening', 'Reading', 'Writing', 'Speaking', 'TotalScore'],
    'IELTS': ['Listening', 'Reading', 'Writing', 'Speaking', 'TotalScore'],
    'PTE': ['Listening', 'Reading', 'Writing', 'Grammar', 'Oral Fluency', 'Pronunciation', 'Spelling', 'Vocabulary', 'Written Discourse', 'TotalScore']
 };

//  const [hasLanguageScore, setHasLanguageScore] = useState(false);

//  useEffect(()=>{
//     setHasLanguageScore(formData?.English_Language_Proficiency.HadLanguageScore || false);
//  }, formData.English_Language_Proficiency)

  //month year dropdown handler 
  const handleDateChange = (date) => {
        if (languageTestSelectedDate.month !== date.month || languageTestSelectedDate.year !== date.year) {
            setLanguageTestSelectedDate(date); 
            const updatedData = [...formData['English_Language_Proficiency']];
            if (updatedData[0]) {
                updatedData[0]['Date'] = date; 
            } else {
                updatedData.push({ Date: date });
            }
            setFormData((prev) => ({ ...prev, English_Language_Proficiency: updatedData }));
            setIsError(false);
        }
  };

  const handleLanguageTestChange = (e, test, fields) => {

    const existingTest = formData.English_Language_Proficiency.find(item => item.test === test);
    
    if (test === "" || existingTest) {
        return;
    }
    //delete last one 
    setFormData(prevFormData => ({
        ...prevFormData,
        English_Language_Proficiency: []
    }));
    //create new one 
    const newTest = { test: test };

    fields.forEach(field => {
        newTest[field] = 0;

    });

    setFormData(prevFormData => ({
        ...prevFormData,
        English_Language_Proficiency: [
            ...prevFormData.English_Language_Proficiency,
            newTest  
        ]
    }));

    console.log('Updated formData:', formData);
};

  const updateTotalLanguageScore = () => {
    const totalScore = formData.English_Language_Proficiency &&
        formData.English_Language_Proficiency.length > 0
        ? Object.entries(formData.English_Language_Proficiency[0])
            .filter(([key]) => LanguageTestFields[formData.English_Language_Proficiency[0].test].slice(0, -1).includes(key))
            .reduce((total, [_, num]) => total + (Number(num) || 0), 0)
        : 0;

    setTotalLanguageScore(totalScore);
    const updatedData = [...formData['English_Language_Proficiency']];
    if (updatedData[0]) {
        updatedData[0]['TotalScore'] = totalScore;
    } else {
        updatedData.push({TotalScore : totalScore});
    }
    setFormData((prev) => ({ ...prev, English_Language_Proficiency: updatedData }));
    setIsError(false); 
  };


  return (
    <div className="mt-4 space-y-2">
      <h3 className="font-bold">English language proficiency test</h3>
      <p className="text-sm">
        Students from a country where the language of instruction is not English must demonstrate proficiency in
        English to qualify for direct admissions.
      </p>

      <RadioButtonGroup
        label="Do you have a TOEFL, IELTS, Duolingo, or PTE test (planned or completed) to report?*"
        name="hasTest"
        options={[
          { label: 'Yes', value: true },
          { label: 'No', value: false }
        ]}
        selectedValue={hasLanguageTest}
        onChange={(value) => {
          setHasTest(value);
          setTestScores((prev) => ({ ...prev, English_Language_Proficiency: value }));
           //delete all the english exam 
          if(!value){
            setFormData((prev) => ({ ...prev, English_Language_Proficiency: [] }));
          }
        }}
      />

      {hasLanguageTest && (
        <div className="space-y-2">
          <div>
            <label className="font-semibold mb-2 text-gray-700">Which test did you take?</label>
            <div className="flex flex-row gap-2">
              <select
                value={formData.English_Language_Proficiency[0]?.test || ''}
                onChange={(e) => {
                  handleLanguageTestChange(e, e.target.value, LanguageTestFields[e.target.value]);
                  setHasLanguageTestName(true);
                }}
                className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="" disabled>
                  Select...
                </option>
                {Object.keys(LanguageTestFields).map((test) => (
                  <option key={test} value={test}>
                    {test}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {hasLanguageTestName && (
            <div>
             
              <MonthYearDropdown onDateChange={handleDateChange} date={languageTestSelectedDate} />
              <RadioButtonGroup
                  label="Have you received your scores yet?"
                  name="hasLanguageScore"
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  selectedValue={!!formData.English_Language_Proficiency[0]?.HadLanguageScore || false}
                  onChange={handleHadLanguageScore} 
               /> 
            </div>
          )}
        </div>
      )}

      {hasLanguageScore && (
        <div className="space-y-2">
          <div className="flex items-center">
            <p className="font-semibold">Scores</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {LanguageTestFields[formData.English_Language_Proficiency[0]?.test]
            ?.filter((field)=> field !== 'TotalScore')
            ?.map((field) => (
              <InputField
                key={field}
                label={field}
                name={'LanguageTest' + field}
                value={formData.English_Language_Proficiency[0]?.[field] || 0}
                onChange={(e) => {
                  handleInputChange(e, 'English_Language_Proficiency', field, e.target.value, 0);
                  updateTotalLanguageScore();
                }}
                type="number"
                min={0}
              />
            ))}
          </div>
          <div>Total: {totalLanguageScore}</div>
        </div>
      )}
    </div>
  );
};

export default EnglishLanguageSession;