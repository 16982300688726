import React, { useState, useEffect } from 'react';
import '../EditProfile.css';
import edit_2 from '../../icons/profile/edit_2.png';
import {get_preferences, add_preferences} from '../../request/profile/preferences_setting_api';

const PreferenceSetting = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [savedPreferences, setSavedPreferences] = useState([]);
    const [selectedPreferences, setSelectedPreferences] = useState(savedPreferences);
    const [errorMessage, setErrorMessage] = useState(""); 
    const maxSelection = 5;

    const preferences = {
        "Academics": [
            'Experimental learning',
            'Collaborative learning',
        ],
        "Student Life": [
            "Clubs and extracurriculars",
            "Emotional and mental health resources",
            "Friendly to LGBTQIA+ students",
            "Racial and cultural diversity",
            "Welcoming and supportive campus",
            "Lots of opportunity to socialize",
            "Big sporting events",
        ],
        "Cost and Outcomes": [
            "Affordable tuition",
            "Getting a good job in my field",
            "Support to find internships",
            "Making a positive impact on the world",
        ],
    };

    const handleSelection = (e, preference) => {
        e.preventDefault();
        if (selectedPreferences.includes(preference)) {
            setSelectedPreferences(selectedPreferences.filter(item => item !== preference));
            setErrorMessage(""); 
        } else if (selectedPreferences.length < maxSelection) {
            setSelectedPreferences([...selectedPreferences, preference]);
            setErrorMessage("");
        } else {
            setErrorMessage(`You can only select up to ${maxSelection} preferences.`);
        }
    };

    const openModal = () => {
        setSelectedPreferences(savedPreferences);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setErrorMessage(""); 
    };

    const handleSubmition = async (e) => {
        e.preventDefault();
        //check valid 
        if(selectedPreferences.length < maxSelection){
            setErrorMessage(`Please selece ${maxSelection} preferences!!`);
            return;
        }
        await add_preferences({preferences: selectedPreferences});
        setSavedPreferences(selectedPreferences);
        setErrorMessage('');
        closeModal();
    };

    useEffect(() => {
        const fetchData = async () => {
            const newData = await get_preferences();
            if (newData && newData.preferences) {
                const data = newData.preferences;
                setSavedPreferences(data);
                setSelectedPreferences(data);
            }
        };
        fetchData();
    }, []);

    const editButton = (buttonText) => {
        return (
            <div>
                <button 
                    onClick={openModal} 
                    className={buttonText === 'Edit' ? "text-[#30394A] px-4 py-2 rounded text-sm" : "text-sm border border-[#042F53] rounded-md px-3 text-[#042F53]"}>
                    {buttonText}
                    {buttonText === 'Edit' && <img src={edit_2} alt="edit" className="w-4 h-4 inline-block ml-2 mb-1" />}
                </button>
                {isOpen && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center w-full">
                        
                        <div className="bg-white p-6 rounded-lg shadow-lg md:ms-80 ms-10 me-10 h-3/5 overflow-y-auto">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-xl font-bold">Edit Top 5 Preferences (What matters most?)</h2>
                                <div onClick={closeModal} className="text-2xl cursor-pointer">&times;</div>
                            </div>

                            <form>
                                <div className="flex flex-col gap-6 mb-4">
                                    <p className="text-gray-700">Select your top 5 college preferences.</p>

                                    {Object.keys(preferences).map((category) => (
                                        <div key={category} className="mt-4">
                                            <h3 className="text-md font-semibold text-gray-700">{category}</h3>
                                            <div className="flex flex-wrap gap-2 mt-2">
                                                {preferences[category].map((preference) => (
                                                    <button
                                                        key={preference}
                                                        className={`px-4 py-2 rounded-full border text-sm font-medium 
                                                        ${selectedPreferences.includes(preference) ? "bg-cyan-600 text-white shadow-2xl" : "text-white bg-[#78BEE3] hover:bg-cyan-600"}`}
                                                        onClick={(e) => handleSelection(e, preference)}
                                                    >
                                                        {preference}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    ))}

                                    <div className="mt-6">
                                        <p className="text-sm text-gray-500">Selected {selectedPreferences.length} out of {maxSelection} preferences</p>
                                    </div>

                                    {/* Error message display */}
                                    {errorMessage && (
                                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                            <div className="block sm:inline">{errorMessage}</div>
                                        </div>
                                    )}
                                </div>

                                <div className="flex justify-end">
                                    <button 
                                        className="bg-[#8FC1D6] text-white px-4 py-2 rounded-md hover:bg-[#8FC1D6] shadow-2xl"
                                        onClick={handleSubmition}
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="flex flex-col justify-between space-x-1 bg-cover bg-no-repeat bg-center rounded-[16px] shadow-md p-4 gap-8 session-header bg-[#FFFFFF] h-full">
            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-col">
                    <div className='text-xl mx-4 font-semibold text-[#042F53]'>Preference Setting</div>
                </div>
                {editButton('Edit')}
                
            </div>
            

            <div className="flex mx-4 items-stretch gap-16 justify-between mb-5">
                <p className="ms-4 self-end me-12 text-sm text-[#7591A3] font-bold">I care about...</p>
                <div className="flex flex-wrap gap-2 me-3">
                    {savedPreferences.length > 0 ? savedPreferences.map((preference) => (
                        <p className="px-2 py-2 rounded-md border text-xs font-medium text-white font-semibold bg-[#78BEE3]" key={preference}>{preference}</p>
                    )) : editButton('+ Add')}
                </div>
            </div>
        </div>
    );
};

export default PreferenceSetting;