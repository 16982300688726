// Note: This is the Dashboard component
import SideBar from "../sidebar/LightSideBar"
import navigation from "../sidebar/Navigator"
import EditingCard from "./components/EditingCard";

export default function EditingDetails() {
    for (var i = 0; i < navigation.length; i++) {
        console.log(navigation[i]["name"]);
        if (navigation[i]["name"] == "History") {
            navigation[i]["current"] = true;
        } else {
            navigation[i]["current"] = false;
        }
    }
    
    const main_content = (
        <main className="bg-white min-h-screen">
            <div className="p-6">
                <p className="text-2xl font-semibold leading-10 text-gray-900">Your Essay Report</p>
            </div>
            <div className="p-4"><EditingCard/></div>
        </main>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation} />
    )
}

