import React from "react";

export default function ProgressBar() {
    const progress = 50;
    const circumference = 2 * Math.PI * 40; // Radius is 40
  const offset = circumference - (progress / 100 * circumference);

  return (
    <div className="relative w-20 h-20">
      <svg className="w-full h-full" viewBox="0 0 100 100">
        {/* Background Circle */}
        <circle
          className="stroke-current text-gray-300"
          strokeWidth="8"
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
        />
        {/* Foreground Circle */}
        <circle
          className="stroke-current text-blue-500"
          strokeWidth="8"
          strokeLinecap="round"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          cx="50"
          cy="50"
          r="40"
          fill="transparent"
          style={{ transition: 'stroke-dashoffset 0.35s' }}
        />
        {/* Percentage Text */}
        <text
          x="50"
          y="52" // Slight adjustment to vertically center the text
          fill="black"
          fontFamily="Verdana"
          fontSize="14"
          textAnchor="middle"
          alignmentBaseline="middle"
          className="font-semibold"
        >
          {`${progress}%`}
        </text>
      </svg>
    </div>
  );
};