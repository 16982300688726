import axios from './index';
    
export const save_outline = async (uuid, outline) => {
    const data = {
        uuid: uuid,
        outline: outline
    };

    try {
        if (outline !== "") {
            const response = await axios.post('college_bs/set_outline', data);
        }
        window.location.href = `/essay?uuid=${uuid}`;
        alert("Success!");
    } catch (error) {
        // 错误处理
        console.error('Error during sign in:', error);
        alert("Network error")
    }
};

export const save_essay = async (uuid, essay) => {
    const data = {
        uuid: uuid,
        essay: essay
    };

    try {
        if (essay !== "") {
            const response = await axios.post('college_bs/set_essay', data);
        }
        
        window.location.href = `/dashboard`;
        alert("Success!");
    } catch (error) {
        // 错误处理
        console.error('Error during sign in:', error);
        alert("Network error")
    }
};

export const signin = async (email, password) => {
    const data = {
        email: email,
        password: password
    };

    try {
        const response = await axios.post('user_auth/login', data);
        // 存储token到本地存储
        localStorage.setItem('token', response.data.token);

        //for testing
        console.log('token')
        console.log(response.data.token)


        // 导航到仪表板页面
        window.location.href = '/dashboard';
        return response.data;
    } catch (error) {
        // 错误处理
        console.error('Error during sign in:', error);
        alert("Password or Email is wrong")
    }
};

export const verify_code = async (email) => {
    const data = {
        email: email
    };

    if (email === "") {
        alert("Please enter your email");
    } else if (!email.includes("@")) {
        alert("Invalid email format");
    } else {
        try {
            const response = await axios.post('user_auth/verify', data);
            return response.data;
        } catch (error) {
            console.error('Error during sign in:', error);
        }
    }
}

export const forgot_password = async (email, new_password, verification_code) => {
    const data = {
        email: email,
        new_password: new_password,
        verification_code: verification_code
    };

    if (email === "") {
        alert("Please enter your email");
    } else if (!email.includes("@")) {
        alert("Invalid email format");
    } else {
        try {
            const response = await axios.post('user_auth/forgot_password', data);
            window.location.href = '/signin';
        } catch (error) {
            if (error.response.status === 409) {
                alert("This verification code was used");
            } else if (error.response.status === 401) {
                alert("Invalid verification code");
            } else if (error.response.status === 404) {
                alert("User not found");
            } else {
                console.error('Error during sign in:', error);
            }
        }
    }
}

export const signup = async (email, username, password, verification_code, source, user_grade) => {
    console.log(source);
    console.log(user_grade);
    
    try {
        const data = {
            email: email,
            username: username,
            password: password,
            verification_code: verification_code,
            source: source,
            user_grade: user_grade,
        };

        const response = await axios.post('user_auth/signup', data);

        console.log(response);
        // Handle the response as needed, Store the token into local storage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', username);

        //for testing 
        console.log('token')
        console.log(response.data.token)
    

        // Navigate to the dashboard page
        window.location.href = '/dashboard';
    } catch (error) {
        // If 409 in response, then the email has been registered
        if (error.response.status === 409) {
            alert("This email has been registered / verification code was used");
        } else if (error.response.status === 401) {
            alert("Invalid verification code");
        }
        else {
            alert("This username has been registered / verification code was used");
            console.error('Error during sign up:', error);
        }
    }
};




