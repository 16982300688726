// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input textarea {
    width: 100%; /* Fill the width of its container */
    padding: 15px; /* Slightly more padding for better spacing */
    border-radius: 20px; /* More pronounced rounded corners */
    border: 2px solid #E2E8F0; /* Lighter border color for a softer look */
    font-family: inherit; /* Match the font styles of the surrounding text */
    font-size: 16px; /* Maintain readable text size */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    resize: none; /* Disable resizing of the textarea */
    transition: border-color 0.2s ease-in-out; /* Smooth transition for border color */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    min-height: 50px; /* Minimum height to accommodate a single line of text */
}

.input textarea:focus {
    outline: none; /* Remove default focus outline */
    border: 2px solid #A0AEC0; /* A more noticeable border color on focus */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* More pronounced shadow on focus for a 'lifted' effect */
}`, "",{"version":3,"sources":["webpack://./src/brainstorm/chatbot/components/Input.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,oCAAoC;IACjD,aAAa,EAAE,6CAA6C;IAC5D,mBAAmB,EAAE,oCAAoC;IACzD,yBAAyB,EAAE,2CAA2C;IACtE,oBAAoB,EAAE,kDAAkD;IACxE,eAAe,EAAE,gCAAgC;IACjD,wCAAwC,EAAE,4BAA4B;IACtE,YAAY,EAAE,qCAAqC;IACnD,yCAAyC,EAAE,uCAAuC;IAClF,sBAAsB,EAAE,uEAAuE;IAC/F,gBAAgB,EAAE,wDAAwD;AAC9E;;AAEA;IACI,aAAa,EAAE,iCAAiC;IAChD,yBAAyB,EAAE,4CAA4C;IACvE,wCAAwC,EAAE,0DAA0D;AACxG","sourcesContent":[".input textarea {\n    width: 100%; /* Fill the width of its container */\n    padding: 15px; /* Slightly more padding for better spacing */\n    border-radius: 20px; /* More pronounced rounded corners */\n    border: 2px solid #E2E8F0; /* Lighter border color for a softer look */\n    font-family: inherit; /* Match the font styles of the surrounding text */\n    font-size: 16px; /* Maintain readable text size */\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */\n    resize: none; /* Disable resizing of the textarea */\n    transition: border-color 0.2s ease-in-out; /* Smooth transition for border color */\n    box-sizing: border-box; /* Include padding and border in the element's total width and height */\n    min-height: 50px; /* Minimum height to accommodate a single line of text */\n}\n\n.input textarea:focus {\n    outline: none; /* Remove default focus outline */\n    border: 2px solid #A0AEC0; /* A more noticeable border color on focus */\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* More pronounced shadow on focus for a 'lifted' effect */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
