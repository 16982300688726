import SideBar from "../sidebar/LightSideBar";
import Bars from "../components/Bars";
import Tiptap from "../editor/components/TipTap";
import { useLocation } from 'react-router-dom';
import React from "react";
import { useEffect } from "react";
import "./Outline.css";
import navigation from "../sidebar/Navigator"
import { save_outline } from "../request/api";
import VoiceHome from '../voicebot/voicebot.tsx';
import Tabs from "./components/Navigator.jsx";

export default function Outline() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uuid = queryParams.get('uuid'); // UUID

    const steps = [
        { id: '01', name: 'Brainstorming', description: 'Engage with our AI tutor to explore various essay ideas and themes. ', href: `/brainstorm?uuid=${uuid}`, status: 'complete' },
        { id: '02', name: 'Outline', description: 'Transform your brainstormed ideas into a structured outline.', href: `/outline?uuid=${uuid}`, status: 'current' },
        { id: '03', name: 'Essay', description: 'With a detailed outline in place, its time to write your essay. ', href: `/essay?uuid=${uuid}`, status: 'upcoming' },
    ]

    const [editorContent, setEditorContent] = React.useState('');
    const [outlineContent, setOutlineContent] = React.useState('<p></p><p>Type or paste your text here 🌟: </p>');
    const [loadingStatus, setLoadingStatus] = React.useState(0);
    const [error, setError] = React.useState(null);
    const [showLoader, setShowLoader] = React.useState(true); // 控制加载动画的显示

    // Add a method to update the outline content when click the button

    function htmlToMarkdown(htmlText) {
        // Convert strong and b tags to Markdown bold
        let markdown = htmlText.replace(/<(?:strong|b)>(.*?)<\/(?:strong|b)>/g, '**$1**');

        // Replace <br> and <br /> with newline characters
        markdown = markdown.replace(/<br\s*\/?>/g, '\n');

        // Convert <p> tags to newline characters
        markdown = markdown.replace(/<\/?p>/g, '\n');

        // Trim excess newlines and return
        return markdown.trim();
    }

    async function handleSubmit(e) {
        e.preventDefault(); // Prevent default form submission

        await save_outline(uuid, htmlToMarkdown(editorContent));
    }

    useEffect(() => {
        const fetchOutline = async () => {
            setShowLoader(true); // 在开始获取数据时显示加载动画

            if (loadingStatus == 0) { // Loading 
                try {
                    setOutlineContent("<p></p><p></p>");
                    const data = {
                        "uuid": uuid
                    }
                    const response = await fetch("https://bestucollege.com/api/college_bs/get_outline", {
                        method: "POST",
                        headers: {
                            "Authorization": "Bearer " + localStorage.getItem("token"),
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data)
                    });

                    if (!response.ok) {
                        throw new Error("HTTP request failed");
                    }
                    
                    const result = await response.json();
                    console.log(result);

                    const formattedOutline = result["outline"]
                        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                        .replace(/\n/g, "<br />");
                    setOutlineContent(formattedOutline);
                    setLoadingStatus(2);
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoadingStatus(2);
                    setShowLoader(false); // 数据获取完成后隐藏加载动画
                }
            }
        };

        fetchOutline();
    }, []); // The empty array makes this effect run only on mount

    const main_content = (
        <main className="outline-container pt-8">
            <Tabs steps={steps}/>

            <div class="flex">
                <div class="flex-1 w-32">
                    <div class="max-w-6xl mx-auto p-10 rounded-lg">
                        <div
                            class="bg-white px-16 pt-4 h-screen shadow rounded-2xl"
                            style={{
                                height: 'calc(100vh - 50px)',
                                overflowY: 'auto'
                            }}
                        >
                            <Tiptap setEditorContent={setEditorContent} text={outlineContent} />
                            {showLoader && <div className="loader"></div>}
                        </div>
                    </div>
                </div>

                {/* <div class="flex-1 w-32">
                    <div class="max-w-6xl mx-auto p-10 rounded-lg">
                        <VoiceHome isTalkingOutline={true} uuid={uuid} setOutline={setEditorContent}/>
                    </div>
                </div> */}
            </div>
        </main>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation} />
    )
}

