import React from "react";
import nameAvatar from '../../images/avatar.png';

export default function VoiceChatHeader() {
    const titleText = "AI Chat";

    return (
        <div className="voiceHeader">
            <div className="grid grid-cols-8 gap-4 items-center">
                <div className=" col-start-2 col-end-8 font-bold"> {titleText} </div>
                <img src={nameAvatar} alt="Avatar" className="w-4/5 rounded-full border-2 border-white object-cover" style={{ aspectRatio: '1 / 1' }} />
            </div>
        </div>
    );
}