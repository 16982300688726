import axios from '../index';

export const get_intended_major = async () => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    };
    try {
        const response = await axios.get('profile/intended-major', config);
        console.log('successfully get the intended major!!');
        console.log(response.data);
        return response.data;

    } catch (error) {
        return null;
    }
}


export const add_intended_major = async (data) => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
        }
    };
    try {
        const response = await axios.post('profile/intended-major', data, config);
        window.location.href = '/edit-profile';
    } catch (error) {
        console.error('Error during add a intended major:', error);
    }
}

export const add_interested_research = async (data) => {

    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
        }
    };
    try {
        const request = {'interested_research_field': data};
        const response = await axios.post('profile/intended-major/add-interested-research-field', request, config);
        window.location.href = '/edit-profile';
    } catch (error) {
        console.error('Error during add a interested field:', error);
    }
}