import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight'
import StarterKit from '@tiptap/starter-kit';
import './TipTap.css'
import { Helmet } from 'react-helmet';
import bold_icon from '../../icons/bold.svg';
import italic_icon from '../../icons/italic.svg';
import { useEffect } from 'react';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <>
      <button onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}>
        h1
      </button>
      <button onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}>
        h2
      </button>
      <button onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}>
        h3
      </button>
      <button onClick={() => editor.chain().focus().setParagraph().run()} className={editor.isActive('paragraph') ? 'is-active' : ''}>
        paragraph
      </button>
      <button onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'is-active' : ''}>
        bold
      </button>
      <button onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'is-active' : ''}>
        italic
      </button>
      <button onClick={() => editor.chain().focus().toggleStrike().run()} className={editor.isActive('strike') ? 'is-active' : ''}>
        strike
      </button>
      <button onClick={() => editor.chain().focus().toggleHighlight().run()} className={editor.isActive('highlight') ? 'is-active' : ''}>
        highlight
      </button>
      <button onClick={() => editor.chain().focus().setTextAlign('left').run()} className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}>
        left
      </button>
      <button onClick={() => editor.chain().focus().setTextAlign('center').run()} className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}>
        center
      </button>
      <button onClick={() => editor.chain().focus().setTextAlign('right').run()} className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}>
        right
      </button>
      <button onClick={() => editor.chain().focus().setTextAlign('justify').run()} className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}>
        justify
      </button>
    </>
  )
}

const Tiptap = ({ setEditorContent, text }) => {
  const [wordCount, setWordCount] = React.useState(0);
  const [cursePosition, setCursePosition] = React.useState(0);

  function cleanText(input) {
    // Create a virtual DOM element to host the input HTML content.
    const virtualElement = document.createElement('div');
    virtualElement.innerHTML = input;
  
    // Extracting the raw text, ignoring all HTML tags.
    const rawText = virtualElement.textContent;
  
    // Cleaning the text to keep only letters (upper and lowercase), spaces, and new lines.
    const cleanedText = rawText.replace(/[^a-zA-Z\s]+/g, '');
  
    // Splitting cleaned text by new lines and wrapping each line with <p></p>, forming an array of HTML strings.
    const wrappedInParagraphs = cleanedText.split('\n').map(text => text.trim());
  
    // Joining the array back into a single HTML string.
    const finalHtml = wrappedInParagraphs.join('');
  
    return finalHtml;
  }  

  

  const editor = useEditor({
    extensions: [
      StarterKit,
      Highlight.configure({ multicolor: true })
    ],
    content: text,
    onUpdate({ editor }) {
        console.log(editor.getHTML()); 
        const content = editor.getHTML();
        const cleaned_content = cleanText(content);
        const word_count = cleaned_content.split(' ').length;
        console.log('word_count', word_count);
        console.log('curse position', editor.state.selection.$cursor.pos);
        setCursePosition(editor.state.selection.$cursor.pos);
        setWordCount(word_count);
        setEditorContent(content);
    },
  });

  useEffect(() => {
    editor?.commands.setContent(text);
    editor?.commands.focus(cursePosition);
  }, [text]);

  return (
    <div className="application">
        <Helmet>
          <link 
            rel="preload" 
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" 
            as="style" 
            onload="this.onload=null;this.rel='stylesheet'"  // This ensures it acts as a stylesheet after preload
          />
          <link 
            rel="stylesheet" 
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" 
          />
        </Helmet>
        <div class="flex items-center justify-between p-2 bg-gray-100 border-b border-gray-200">
        <div class="flex gap-2">
          <button class="p-1 bg-white rounded shadow-sm hover:bg-gray-50"><img className="h-4 w-4" src={bold_icon} alt="Bold" /></button>
          <button class="p-1 bg-white rounded shadow-sm hover:bg-gray-50"><img className="h-4 w-4" src={italic_icon} alt="Bold"/></button>
          <button class="p-1 bg-white rounded shadow-sm hover:bg-gray-50">U</button>
          <button class="p-1 bg-white rounded shadow-sm hover:bg-gray-50">|</button>
          <button class="p-1 bg-white rounded shadow-sm hover:bg-gray-50">H1</button>
          <button class="p-1 bg-white rounded shadow-sm hover:bg-gray-50">H2</button>
        </div>

          <div class="flex items-center gap-2">
            <span class="text-sm text-gray-600">{wordCount} words</span>
          </div>
        </div>
        <div>
           <EditorContent className='editor-content' editor={editor} />
        </div>
    </div>
  );
};

export default Tiptap;