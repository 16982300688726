import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import LightSideBar from '../sidebar/LightSideBar'
import SideBar from '../sidebar/LightSideBar'
import React, { useState } from 'react';
import navigation from '../sidebar/Navigator';
import Extracurriculars from './components/Extracurriculars';
import backgroundImage from '../images/background.svg';


export default function DetailExtracurriculars() {

  const main_content = (
    <main  style={{ backgroundImage: `url(${backgroundImage})` }} className="grid grid-cols-2 gap-4 px-5">
      
    </main>
  )

  return (
    <SideBar main_content={main_content} navigation={navigation}>
    </SideBar>
  )
}
