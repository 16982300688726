import React from 'react';
import nameAvatar from '../../images/avatar.png';
import edit from '../../icons/profile/edit.png';
import nameCardImage from '../../images/namecard.svg';
import user from '../../icons/profile/user.png';
import school from '../../icons/profile/school.png';
import location from '../../icons/profile/location.png';
import '../EditProfile.css';

const NameCard = () => {

    return (

    <div style={{ backgroundImage: `url(${nameCardImage})` }} className={`flex mb-4 space-x-4 items-start bg-cover bg-no-repeat bg-center basis-5/12 rounded-[16px] shadow-md items-center`}>
        <div class="flex flex-row items-center space-x-4 p-5 my-3">
            <img src={nameAvatar} alt="Avatar" className="w-16 h-16 rounded-full border-2 drop-shadow-md object-cover" style={{ aspectRatio: '1 / 1' }}/>
            <div class="basis-1/2">
                <p className="text-l font-semibold text-white">BestU</p>
                <p className="text-xs font-semibold text-white underline">Ammi00@gmail.com</p>
                <button className = "flex gap-2 items-center">
                    <div id ='edit-text' className = 'text-xs'>
                        Edit
                    </div>
                    <img src={edit} alt="Edit" className="w-3 h-3 object-cover" style={{ aspectRatio: '1 / 1' }}/>
                </button>
            </div>
        </div>
        <div className="grid grid-rows-3 p-4 gap-y-2 text-xs text-white my-3">
            <div className = 'flex flex-row gap-2 items-center'>
               <img src = {user} alt = 'user' className = 'w-5 h-5' ></img>
               <div id = 'name-card-text' className="bg-white text-black rounded-[5px] px-2">Female</div>
               <div id = 'name-card-text' className = 'bg-white text-black rounded-[5px]  px-2'>China</div>
               <div id = 'name-card-text' className = 'bg-white text-black rounded-[5px] px-2 '>Class of '21</div>
               <div id = 'name-card-text' className = 'bg-white text-black rounded-[5px]  px-2'>Enrolling in Fall 2025</div>
            </div>
            <div className = 'flex flex-row gap-2 items-center ms-1.5'>
                <img src = {location} className = 'w-2 h-3'></img>
                <div className = 'ms-1.5'>New York City, USA</div>
            </div>
            <div className = 'flex flex-row gap-2 items-center ms-1'>
                <img src = {school} className = 'w-3 h-3'></img>
                <div className = 'ms-1'>National High School</div>
            </div>
        </div>
    </div>
     
   );
}

export default NameCard;
