// Sidebar.js
import React from 'react';

const Details = ({ title, content }) => {
    return (
        <div class="flex flex-col bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] mb-4">
        <div class="bg-gray-100 border-b rounded-t-xl py-3 px-4 md:py-4 md:px-5 dark:bg-gray-800 dark:border-gray-700">
            <p class="mt-1 text-sm text-gray-500 dark:text-gray-500">
            Featured
            </p>
        </div>
        <div class="p-4 md:p-5">
            <h3 class="text-lg font-bold text-gray-800 dark:text-white">
            {title}
            </h3>
            <p class="mt-2 text-gray-800 dark:text-gray-400">
            {content}
            </p>
            
        </div>
        </div>
    );
};

export default Details;

