import React from 'react';

const RadioButtonGroup = ({ label, name, options, selectedValue, onChange }) => {
  return (
    <div>
      <div className="flex items-center">
        <label className="font-semibold">{label}</label>
      </div>
      <div className="flex space-x-4">
        {options.map((option) => (
          <label key={option.value}>
            <input
              type="radio"
              name={name}
              value={option.label}
              defaultChecked={selectedValue === option.value}
              onChange={(e) => onChange(option.value)}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioButtonGroup;