import React from "react";
import './Popup.css'
import Details from "./Details";


const Popup = ({ trigger, setTrigger, currPos, setCurrPos, responseContent }) => {
    console.log(currPos);

    
    return (trigger) ? (
        <div className="popup">
            <div className="popup-inner">

        <Details title="Feedback" content={responseContent[currPos]["feedback"]}/>
        <Details title="Editing" content={responseContent[currPos]["editing"]}/>
        <Details title="Brainstorm Questions" content={responseContent[currPos]["brainstorm_questions"]}/>

        <p></p>

        <div class="flex justify-center items-center">
        <button type="button" class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md bg-blue-50 border border-transparent font-semibold text-blue-500 focus:outline focus:ring-2 ring-offset-white focus:ring-blue-500 focus:ring-offset-2 transition-all" onClick={() => setTrigger(false)}>
        Exit </button>
        </div>
            </div>
        </div>
    ) : "";
}

export default Popup