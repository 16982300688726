/* eslint-disable @typescript-eslint/no-unused-expressions */

"use client";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Lines from "./_components/lines";
import mic from "../icons/mic.png";
import {
  AudioLinesIcon,
  KeyboardIcon,
  SquareIcon,
  CircleUserIcon,
  MicIcon,
  SendHorizonalIcon,
} from "lucide-react";
import {
  AudioConfig,
  ResultReason,
  SpeakerAudioDestination,
  SpeechConfig,
  SpeechRecognizer,
  SpeechSynthesizer,
} from "microsoft-cognitiveservices-speech-sdk";
import { obtainToken } from "./actions/tokenGenerator.ts";
import { Message, useChat } from "ai/react";
import AiIcon from "./_components/aiIcon";
import { cn } from "./lib/utils";
import { OpenAI } from "openai";

import Microphone from "./_components/microphone";
import Messages from "../brainstorm/chatbot/components/Messages";
import micBackground from "../icons/micBackground.png";
import "./Voicebot.css";
import VoiceChatHeader from "./_components/VoiceChatHeader";
import Mic from "./_components/Mic";
import {add_rag_school, add_rag_activities} from '../request/rag'



type SetRecognizedTranscript = Dispatch<SetStateAction<string>>;
type SetRecognizingTranscript = Dispatch<SetStateAction<string>>;

const openai = new OpenAI({
  apiKey: "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",
  dangerouslyAllowBrowser: true
});

const useSpeechRecognition = (
  setRecognizedTranscript: SetRecognizedTranscript,
  setRecognizingTranscript: SetRecognizingTranscript
) => {
  const [speechRecognizer, setSpeechRecognizer] =
    useState<SpeechRecognizer | null>(null);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [speechConfig, setSpeechConfig] = useState<SpeechConfig | null>(null);


  //Get School
  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             await add_rag_school(school);
  //             await add_rag_activities(school);
  //         } catch (error) {
  //             console.error('Error during rag process:', error);
  //         }
  //     };
  //     fetchData(); 
  // }); 

  useEffect(() => {
    const initializeSpeechSDK = async () => {
      try {
        const { authToken, region } = await obtainToken();
        const config = SpeechConfig.fromAuthorizationToken(authToken, region);
        config.speechRecognitionLanguage = "zh-CN";

        setSpeechConfig(config);

        // Initialize the AudioContext here in response to a user gesture (button click)
        const context = new AudioContext();
        setAudioContext(context);

        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new SpeechRecognizer(config, audioConfig);

        recognizer.recognizing = (
          _: any,
          event: { result: { reason: any; text: string } }
        ) => {
          // Handle recognizing event
          setRecognizingTranscript(event.result.text);
        };

        recognizer.recognized = (
          _: any,
          event: { result: { reason: any; text: string } }
        ) => {
          if (event.result.reason === ResultReason.RecognizedSpeech) {
            // Update the state with the recognized speech
            setRecognizedTranscript((prev) => prev + " " + event.result.text);
          }
        };

        setSpeechRecognizer(recognizer);
      } catch (error) {
        console.error("Error initializing Speech SDK:", error);
      }
    };
    
    initializeSpeechSDK();
  }, [setRecognizedTranscript]);

  // Start speech recognition
  const handleButtonClick = async () => {
    try {
      if (speechConfig && audioContext) {
        if (!audioContext.state || audioContext.state === "suspended") {
          await audioContext.resume();
        }
        // Start the Speech SDK recognition when the AudioContext is ready
        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new SpeechRecognizer(speechConfig, audioConfig);

        recognizer.recognizing = (_, event) => {
          // Handle recognizing event
          console.log(event.result.text);
          setRecognizingTranscript(event.result.text);
        };

        recognizer.recognized = (_, event) => {
          if (event.result.reason === ResultReason.RecognizedSpeech) {
            // Update the state with the recognized speech
            setRecognizedTranscript((prev) => prev + " " + event.result.text);
            setRecognizingTranscript("");
          }
        };
        recognizer.startContinuousRecognitionAsync();

        setSpeechRecognizer(recognizer);
      }
    } catch (error) {
      console.error("Error handling button click:", error);
    }
  };

  // Stop speech recognition
  const stopRecognition = () => {
    if (speechRecognizer) {
      speechRecognizer.stopContinuousRecognitionAsync();
      setSpeechRecognizer(null);
    }
  };

  return { handleButtonClick, stopRecognition };
};

// Add the uuid
const VoiceHome = ( {uuid, isTalkingOutline, setOutline, setSummary} ) => {
  // Create user message
  const userMessage: Message = {
    id: Date.now().toString(),
    role: "bot",
    content: "Hi! Welcome to BestYouCollege, please say hi to get started.",
    createdAt: new Date(),
  };


  const [recordingReady, setRecordingReady] = useState(false);
  const [recordingStarted, setRecordingStared] = useState(false);
  const [aiResponseFinished, setAiResponseFinished] = useState(false);
  const [typingMode, setTypingMode] = useState(false);
  const [combinedTranscript, setCombinedTranscript] = useState("");

  const [messages, setMessages] = useState<Message[]>([userMessage]);
  const [input, setInput] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  //ensure the chat will automatically scroll to focus on the latest message
  const lastMessageRef = useRef<HTMLDivElement>(null);

  // Call handle submission in the first render
  useEffect(() => {    
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  })

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const API = {
    GetChatbotResponse: async (uuid, message, setSummary) => {
      const data = {
        uuid: uuid,
        dialogue: message,
        bot_type: "Voice"
      };
  
      const response = await fetch(
        "https://bestucollege.com/api/college_bs/get_dialogue",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("HTTP request failed");
      }
  
      const result = await response.json();
      setSummary(result["stage_summary"]);
      console.log("the bs api: ", result);
      return result["answer"];
    },
  };  

  const OutlineAPI = {
    GetChatbotResponse: async (uuid, message, setOutline) => {
      const data = {
        uuid: uuid,
        dialogue: message,
      };
  
      const response = await fetch(
        "https://bestucollege.com/api/college_bs/talk_outline",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("HTTP request failed");
      }
  
      const result = await response.json();
      setOutline(result["new_outline"]);
      console.log("result: ", result);
      return result["answer"];
    },
  }; 

  const handleSubmit = async () => {
    if (isSubmitting) return; // Prevent double submission
    setIsSubmitting(true);
  
    if (input.trim() === "") {
      setIsSubmitting(false);
      return;
    }
  
    // Create user message
    const userMessage: Message = {
      id: Date.now().toString(),
      role: "user",
      content: input,
      createdAt: new Date(),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
  
    console.log("input: ", input);
  
    try {
      let response = "";

      if (isTalkingOutline) {
        response = await OutlineAPI.GetChatbotResponse(uuid, input, setOutline);
        console.log("response: ", response);

      } else{
        response = await API.GetChatbotResponse(uuid, input, setSummary);
      }

      // Generate AI response message
      const aiMessage: Message = {
        id: (Date.now() + 1).toString(),
        role: "assistant",
        content: response,
        createdAt: new Date(),
      };
  
      setMessages((prevMessages) => [...prevMessages, aiMessage]);
  
      setAiResponseFinished(true);
      textToSpeech(aiMessage);
    } catch (error) {
      console.error("Error getting response:", error);
      // Handle error (e.g., display a message to the user)
    }
  
    setIsSubmitting(false);
    setInput(""); // Clear input field
  };
  

  const formRef = useRef<HTMLFormElement>(null);
  let speechToken: any;

  // const [speechRecognizer, setSpeechRecognizer] =
  //   useState<SpeechRecognizer | null>(null);
  // const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  // const [speechConfig, setSpeechConfig] = useState<SpeechConfig | null>(null);
  const [recognizingTranscript, setRecognizingTranscript] = useState("");
  const [recognizedTranscript, setRecognizedTranscript] = useState("");

  const { handleButtonClick, stopRecognition } = useSpeechRecognition(
    setRecognizedTranscript,
    setRecognizingTranscript
  );

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const isUserAtBottom = () => {
    if (!containerRef.current) return false;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    return scrollHeight - scrollTop <= clientHeight + 50; // 50px threshold
  };

  useEffect(() => {
    if (isUserAtBottom()) {
      scrollToBottom(); // Only auto-scroll if the user is near the bottom
    }
  }, [messages]);
  
  // get initial messages
  const initialMessage = async () => {
    try {
      const response = await fetch("http://localhost:4000/api/initialMessage", {
        method: "GET",
      });
      // Check if the response status is OK
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      messages.push(data);
      setAiResponseFinished(false);
      setRecordingReady(false);
      textToSpeech(messages[messages.length - 1]);
    } catch (error) {
      console.error("Error fetching initial message:", error);
    }
  };
  const count = useRef(0);
  useEffect(() => {
    if (messages.length === 0 && count.current == 0) {
      initialMessage();
      setRecordingReady(true);
      count.current += 1;
    }
  }, [messages]);

  // delay for 0.75s
  const shortHold = (currentTextBlock: string, index: number) => {
    if (index == 0 || index === messages.length - 1) {
      //hold for 0.75s
      const timer = setTimeout(() => {
        console.log("Timer Expired");
      }, 750);
      () => clearTimeout(timer);
      return currentTextBlock;
    } else {
      return currentTextBlock;
    }
  };

  // Trigger text to speech
  useEffect(() => {
    console.log("tts has been triggered");
    if (aiResponseFinished && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.role === "assistant") {
        textToSpeech(lastMessage);
      }
      /* if (messages[messages.length - 1].role === "assistant") {
        textToSpeech(messages[messages.length - 1]);
      } */
    }
    console.log(messages);
  }, [aiResponseFinished]);


  const [ttsFinished, setTtsFinished] = useState(true);

  // Text to Speech
  const textToSpeech = async (message: Message) => {
    if (!ttsFinished) return;

    setTtsFinished(false);

    if (!speechToken) {
      const tokenObj = await obtainToken();
      if (tokenObj) {
        console.log("tokenObj: ", tokenObj);
        speechToken = tokenObj;
      }
    }

    const speechConfig = SpeechConfig.fromAuthorizationToken(
      speechToken.authToken,
      speechToken.region
    );

    //speechConfig.speechSynthesisVoiceName = "zh-CN-XiaoyanNeural";
    const speechSynthesizer = new SpeechSynthesizer(speechConfig);

    speechSynthesizer.speakTextAsync(
      message.content,
      (result) => {
        speechSynthesizer.close();
        setAiResponseFinished(false);
        setTtsFinished(true);
        return result.audioData;
      },
      (error) => {
        console.log(error);
        speechSynthesizer.close();
        setTtsFinished(true);
        setAiResponseFinished(false);
      }
    );
  };

  useEffect(() => {
    // dispatch the recognized text to the input field
    if (recognizingTranscript) {
      setCombinedTranscript(recognizedTranscript + recognizingTranscript);
      setInput(combinedTranscript);
      setLastUpdate(Date.now());
    }
  }, [recognizingTranscript, recognizedTranscript, handleInputChange]);

  // Automatically submit the form after 2 seconds of inactivity
  const [lastUpdate, setLastUpdate] = useState(Date.now()); // Track the last time data was updated

  // Submit only if the form is not empty and 2 seconds have passed
  useEffect(() => {
    const interval = setInterval(() => {
      const timeSinceLastUpdate = Date.now() - lastUpdate;
      if (
        !typingMode &&
        !aiResponseFinished &&
        timeSinceLastUpdate >= 2500 &&
        input.length > 0
      ) {
        setInput(combinedTranscript);
        handleSubmit();
        setRecognizedTranscript("");
        setCombinedTranscript("");
      }
      setLastUpdate(Date.now());
      setAiResponseFinished(false);
    }, 2500);

    // Clean up the timer
    return () => clearInterval(interval);
  }, [lastUpdate, aiResponseFinished]);

  // Set up a reactor through useEffect to break the input into multiple lines 
  // and last line (to generate the fading effect)
  const [lines, setLines] = useState<string>("");
  const [lastLine, setLastLine] = useState<string>("");
  // useEffect(() => {
  //   const MAX_CHAR_LIMIT = 30; // Assuming one line is 30 characters

  //   // Calculate the last remaining character of last line
  //   const lastLineChar = input.length % MAX_CHAR_LIMIT;

  //   // Retrieve the previous lines (into one string)
  //   const previousLines = input.substring(0, input.length - lastLineChar);
  //   const lastLine = input.substring(input.length - lastLineChar);

  //   setLines(previousLines);
  //   setLastLine(lastLine);
  // }, [input]);

  useEffect(() => {
    const MAX_CHAR_LIMIT = 30; 
  
    const lastSpaceIndex = input.lastIndexOf(" ", MAX_CHAR_LIMIT);
  
    // If a space is found within the limit, split there. Otherwise, use the character limit directly.
    const splitIndex = lastSpaceIndex !== -1 ? lastSpaceIndex : MAX_CHAR_LIMIT;
  
    // Retrieve previous lines and last line without breaking words
    const previousLines = input.substring(0, splitIndex);
    const lastLine = input.substring(splitIndex).trimStart(); // Trim any leading spaces from the new line
  
    setLines(previousLines);
    setLastLine(lastLine);
  }, [input]);


  return (
    <div className="voicebot">
      <VoiceChatHeader />
      {/* <Input onSend={send} word_limit={word_limit_preset} /> */}
      <div className="w-full flex flex-col h-[70vh] overflow-y-grow pb-4">
        {/* Chat History */}
        <div className="h-full overflow-y-auto pb-4 w-[415px] flex flex-col justify-between items-end">
          <div className="w-full h-full overflow-y-auto scrollbar-hide overflow-anchor">
          {messages.map((message, index) => {
              const isLastMessage = index === messages.length - 1;
              return (
                // Chat History
                <div
                  key={message.id}
                  ref={isLastMessage ? lastMessageRef : null} // Attach ref to the last message
                  className="flex flex-col overflow-anchor no-overflow-anchoring grid grid-cols-10"
                >
                  {message.content.split('\n').map((currentTextBlock, idx) => {
                    const processedText = message.role === 'user' 
                    ? currentTextBlock.replace(/。/g, '.') 
                    : currentTextBlock;

                    if (processedText === '') {
                      return <p key={message.id + idx}>&nbsp;</p>;
                    } else {
                      return (
                        <div
                          key={message.id + idx}
                          className={
                            message.role === 'user'
                              ? 'col-end-13 col-span-6 flex justify-end my-2'
                              : 'col-start-1 col-end-7 flex justify-start my-2'
                          }
                        >

                        <div className = "voiceBotText rounded-lg px-4 py-4 text-lg font-medium ${isLastMessage ? 'text-black' : 'text-gray-500">
                            {shortHold(processedText, idx)}
                        </div>

                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
        </div>
        {/* Buttons at the bottom */}
        {/* <div className="h-fit w-[415px] flex flex-col justify-between items-end my-2"> */}
        <div className="adjusti flex items-center justify-center">
          <div className="flex space-x-4">
            {!typingMode ? (
              //voice mode
              recordingReady ? (
                <div>
                  <button
                    // className="flex justify-end items-center p-0.5 border border-transparent  hover:border-red-500 hover:rounded-sm"
                    onClick={() => {
                      setRecordingReady((prev) => !prev);
                      stopRecognition();
                      // stop the speech synthesis
                    }}
                  >
                    <div className="userInputText">
                      {lines}
                    </div>
                    <div className="userInputTextFade">
                      {lastLine}
                    </div>
                    <div className="adjusti flex items-center justify-center">
                      <Mic micBackground={micBackground} mic={mic} isRotating={true} />
                    </div>

                    <div>
                      <div className='voiceBotText col-start-2 col-end-8 text-1xl font-bold text-center'>
                        {"Listening..."}
                      </div>
                    </div>
                  </button>
                </div>
              ) : (
                <button
                  // className="bg-blue-500 bottom-0 inset-x-0 rounded-lg px-4 font-bold w-[352px] h-[48px] text-blue-500 bg-white hover:bg-blue-500 hover:text-white"
                  onClick={() => {
                    setRecordingReady((prev) => !prev);
                    setRecordingStared((prev) => !prev);
                    setAiResponseFinished(false);
                    handleButtonClick();
                  }}
                >
                  <div className="adjusti flex items-center justify-center">
                    <Mic micBackground={micBackground} mic={mic} isRotating={false} />
                  </div>
                  <span></span>
                </button>
              )
            ) : (
              // text mode
              <div className="flex items-center w-[352px] h-[48px] focus-within:border-blue-500 focus-within:ring-2 focus-within:ring-blue-500 focus-within:rounded-lg">
                <input
                  type="text"
                  className="bg-blue-500 text-black rounded-l-lg font-bold h-[48px] w-full text-blue-600 bg-white px-4 py-2 focus:outline-none"
                  onChange={(e) => {
                    setInput(e.target.value);
                  }}
                  value={input}
                />
                <button
                  className="flex items-center justify-center bg-white h-[48px] w-[48px] rounded-r-lg cursor-pointer "
                  onClick={handleSubmit}
                >
                  <SendHorizonalIcon
                    size={24}
                    className="hover:text-blue-500"
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VoiceHome;