import axios from '../index';


export const get_extracurrculars = async () => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    };
    try {
        const response = await axios.get('profile/extracurriculars', config);
        console.log('successfully get the extracurriculars!!');
        console.log(response.data);
        return response.data;
        
    } catch (error) {
        return null;
    }
}

export const add_extracurrcular = async (data) => {
    
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
        }
    };
    try {
        const response = await axios.post('profile/extracurriculars/add', data, config);
        //for checking
        console.log(response);
        window.location.href = '/edit-profile';
    } catch (error) {
        console.error('Error during add a extracurricular:', error);
    }
}

export const update_extracurrcular = async (data) => {
    
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
        }
    };

    try {
        const response = await axios.post('profile/extracurriculars/update', data, config);
        //for checking
        console.log(response);
        window.location.href = '/edit-profile';
    } catch (error) {
        console.error('Error during add a extracurricular:', error);
    }
}

export const delete_extracurrcular = async (data) => {

    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
        }
    };
    try {
        const response = await axios.delete('profile/extracurriculars/delete', data, config);
        window.location.href = '/edit-profile';
    } catch (error) {
        console.error('Error during delete a extracurricular:', error);
    }
}