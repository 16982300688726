// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.waiting {
    color: #4a90e2;
    animation: pulse 1s infinite ease-in-out;
}
.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

/* Recommended Content */
.outline-container {
    background: radial-gradient(100.6% 317.94% at 0% 0%, rgba(100, 179, 244, 0.2) 0%, rgba(194, 229, 156, 0.1) 14.32%, rgba(100, 179, 244, 0.11) 32.75%, rgba(194, 229, 156, 0.06) 57.1%, rgba(100, 179, 244, 0.12) 76.19%, rgba(194, 229, 156, 0.12) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}`, "",{"version":3,"sources":["webpack://./src/brainstorm/Outline.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,wCAAwC;AAC5C;AACA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;AACtC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC;AACA;IACI,KAAK,mBAAmB,EAAE;IAC1B,MAAM,qBAAqB,EAAE;IAC7B,OAAO,mBAAmB,EAAE;AAChC;;AAEA,wBAAwB;AACxB;IACI,2VAA2V;AAC/V","sourcesContent":[".waiting {\n    color: #4a90e2;\n    animation: pulse 1s infinite ease-in-out;\n}\n.loader {\n    border: 4px solid #f3f3f3;\n    border-top: 4px solid #3498db;\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n    animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}\n@keyframes pulse {\n    0% { transform: scale(1); }\n    50% { transform: scale(1.1); }\n    100% { transform: scale(1); }\n}\n\n/* Recommended Content */\n.outline-container {\n    background: radial-gradient(100.6% 317.94% at 0% 0%, rgba(100, 179, 244, 0.2) 0%, rgba(194, 229, 156, 0.1) 14.32%, rgba(100, 179, 244, 0.11) 32.75%, rgba(194, 229, 156, 0.06) 57.1%, rgba(100, 179, 244, 0.12) 76.19%, rgba(194, 229, 156, 0.12) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
