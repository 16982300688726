/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/



export default function Form(props) {
    const { setMode } = props;

    return (
        <form action="#" method="POST" className="space-y-6">
        <div>
            <label htmlFor="email" className="block text-lg font-bold leading-6 text-gray-900 text-center">
                Editing Mode (2 tokens)
            </label>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              checked
            />
            <label htmlFor="remember-me" className="ml-3 block text-xs leading-6 text-gray-700">
               I agree with the terms and conditions
            </label>
          </div>

          <div className="text-sm leading-6">
            <a href="/agreement" className="font-semibold text-indigo-600 hover:text-indigo-500">
              Service Agreement
            </a>
          </div>
        </div>

        
      </form>
    )
  }
  