import SideBar from "../sidebar/LightSideBar";
import Bars from "../components/Bars";
import Chatbot from "./chatbot/Chatbot";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import navigation from "../sidebar/Navigator"
import "./Brainstorm.css"
import VoiceHome from "../voicebot/voicebot.tsx";
import Tabs from "./components/Navigator.jsx";
import NoteTaker from "./notetaker/NoteTaker.jsx";
import React from "react";

export default function Brainstorm() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uuid = queryParams.get('uuid'); // UUID
    const word_limit = queryParams.get('word_limit'); // word limit
    const bot_type = queryParams.get('bot_type');
    
    const [summary, setSummary] = React.useState('Waiting for discussion');
    
    console.log(word_limit)

    const steps = [
        { id: '01', name: 'Brainstorming', description: 'Engage with our AI tutor to explore various essay ideas and themes. ', href: `/brainstorm?uuid=${uuid}`, status: 'current' },
        { id: '02', name: 'Outline', description: 'Transform your brainstormed ideas into a structured outline.', href: `/outline?uuid=${uuid}`, status: 'upcoming' },
        { id: '03', name: 'Essay', description: 'With a detailed outline in place, it’s time to write your essay. ', href: `/essay?uuid=${uuid}`, status: 'upcoming' },
    ]

    useEffect(() => {
        if (uuid) {
            console.log(word_limit)
            console.log("id");
            console.log(uuid);
        }
    }, [uuid]);


    // Add voice home with uuid
    const main_content = (
        <main className="bs-container pt-8">
            <Tabs steps={steps}/>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex gap-4">

            {bot_type == 'Voice' ? 
               <>
                    <div className="flex-1 h-full">
                        <NoteTaker uuid={uuid} summary={summary} />
                    </div>
        
                    <div className="flex-1 h-full">
                        <VoiceHome uuid={uuid} isTalkingOutline={false} setSummary={setSummary} />
                    </div> 
               </>
            :
            <div>
                <Chatbot uuid={uuid}/>
            </div>
            }
            </div>
        </main>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation} />
    )
}
