import React,  { useState, useEffect } from 'react';
import ProgressBar from '../../components/ProgressBar';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {get_profile_status } from '../../request/profile/profile_status_api';
import ProfileStatus from './ProfileStatus';

const ProfileCompletion = () => {

    const [status, setStatus] = useState({})
    const [rate, setRate] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            const session_completed = await get_profile_status();
            if (session_completed) {
                setStatus(session_completed.status);

                // Calculate the completion rate directly from session_completed.status
                const totalItems = Object.keys(session_completed.status).length;
                const completedItems = Object.values(session_completed.status).filter(item => item === true).length;
                const completionRate = ((completedItems / totalItems) * 100).toFixed(2);
    
                setRate(completionRate);
            }
        };
        fetchData();
    }, []);

    return (
        <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)'}} className="flex flex-row justify-between items-center mb-4 space-x-1 bg-cover bg-no-repeat bg-center basis-5/12 rounded-[16px] shadow-md p-4 space-x-4 gap-12 bg-[#FFFFFF]">
            <div class="flex flex-col space-y-3 ms-6 me-2">
                <div class="flex flex-row items-center space-x-4">
                        <div className="relative">

                        <div style={{ position: 'relative', width: '100px', height: '100px' }}>
                                <svg width="0" height="0">
                                    <defs>
                                    <linearGradient id="gradientColors" x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" stopColor="rgba(100, 179, 244, 1)" />
                                        <stop offset="100%" stopColor="rgba(194, 229, 156, 1)" />
                                    </linearGradient>
                                    </defs>
                                </svg>

                                <CircularProgressbar
                                    value={rate}
                                    text={`${rate}%`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                    rotation: 0.65,
                                    textSize: '14px',
                                    pathTransitionDuration: 0.5,
                                    pathColor: `url(#gradientColors)`,
                                    trailColor: '#FFFFFF',
                                    textColor: '#042F53',
                                    })}
                                    className="circular-progressbar-text"
                                />
                        </div>

                        </div>
                        <h2 className="text-base font-semibold w-32">Your Profile Creation Status</h2>
                </div>

                <div className="label text-sm text-gray-600 w-48">Please update your profile to allow BestU to monitor your progress continuously.</div>

            </div>

            <div className = 'profile-md:block hidden'>
                <ul className="space-y-1">

                {Object.entries(status).map(([label, completed], index) => (
                    <li key={index} className="flex justify-between items-center gap-12">
                        <div className="label text-gray-700 font-medium text-sm me-4">{label}</div>
                        <div
                            className={`inline-flex items-center justify-center w-3 h-3 rounded-full ${
                                completed ? 'bg-[#78BEE3] text-white' : 'border-[1.5px] border-[#808080]'
                            }`}
                        >
                            {completed ? (
                                <svg
                                    className="w-3 h-3"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                </svg>
                            ) : (
                                <div className="w-2.5 h-2.5"></div>
                            )}
                        </div>
                    </li>
                ))}


            </ul>
            </div>
        </div>

    );
}



export default ProfileCompletion;