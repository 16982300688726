import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  Cog6ToothIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import logo from '../icons/logo.png';
import avatar from '../icons/avatar.jpg';


const teams = [

]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SideBar({ main_content, navigation }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  function isLoggedIn() {
    return localStorage.getItem('token') !== null;
  }

  const loggedIn = isLoggedIn(); // 调用函数

  function handleSignOut() {
    console.log(123)
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    // 重定向到登录页面
    window.location.href = '/signin';
  }

  // 创建用户导航链接数组，根据登录状态确定
  const userNavigation = isLoggedIn() ? [
    { name: 'Edit Profile', href: '/edit-profile' },
    { name: 'Sign out', action: handleSignOut },
  ] : [
    { name: 'Sign in', href: '/signin' },
    { name: 'Sign up', href: '/signup' },
  ];

  function getUsername() {
    if (localStorage.getItem('username')) {
      return localStorage.getItem('username');
    } else {
      // Set a default username if not found
      localStorage.setItem('username', '翠🦌');
      return '翠🦌';
    }
  }

  let username = getUsername();

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="./Logo1.png"
                        alt="BestYouCollege"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {userNavigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  onClick={(e) => {
                                    if (item.onClick) {
                                      e.preventDefault(); // 阻止默认行为
                                      item.onClick(); // 执行onClick处理器
                                    }
                                  }}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Y</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? 'text-indigo-600 border-indigo-600'
                                        : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <a
                            href="#"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            Settings
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-20 shrink-0 items-center">
              <a href="/dashboard" aria-label="Go to main page" className = 'flex flex-row items-center gap-4 text-[#042F53] font-normal text-[25px]'> 
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt="Your Company"
                />
                 <div  style={{ textShadow: '0px 4px 4px rgba(99, 177, 242, 0.15)' }}>BestU</div>
            </a>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          onClick={item.action}
                          className={classNames(
                            item.current
                              ? 'bg-[#BBECE94D] text-[#042F53] text-[16px] font-bold items-center'
                              : 'hover:bg-[#BBECE94D] hover:font-bold text-[#042F53] text-[16px] items-center',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6'
                          )}
                        >
                          <img className = 'h-6 w-6' src = {item.icon}/>
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                    href="#"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-[16px] font-medium leading-6 text-[#042F53] hover:bg-[#BBECE94D] hover:font-bold"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-[#042F53] group-hover:text-[#042F53] "
                      aria-hidden="true"
                    />
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        
        <div className="lg:pl-72 bg-white">
          

          {main_content}
        </div>
      </div>
    </>
  )
}
