// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#name-card-text{
    font-size: 10px;
    font-weight: 400;
    color: rgba(87, 125, 147, 1);
}

#edit-text{
    color: rgba(48, 57, 74, 1);
}

.label{
    color: rgba(123, 144, 182, 1);
}

.session-header{
    color: #042F53;
}

.profile-number{
    color: #78BEE3;
    font-size: 25px;
    font-weight: 700;
}

.profile-text{
    color: #7591A3;
}


.outline-container {
    background: radial-gradient(100.6% 317.94% at 0% 0%, rgba(100, 179, 244, 0.2) 0%, rgba(194, 229, 156, 0.1) 14.32%, rgba(100, 179, 244, 0.11) 32.75%, rgba(194, 229, 156, 0.06) 57.1%, rgba(100, 179, 244, 0.12) 76.19%, rgba(194, 229, 156, 0.12) 100%) 
}
`, "",{"version":3,"sources":["webpack://./src/profile/EditProfile.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,4BAA4B;AAChC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;;AAGA;IACI;AACJ","sourcesContent":["#name-card-text{\n    font-size: 10px;\n    font-weight: 400;\n    color: rgba(87, 125, 147, 1);\n}\n\n#edit-text{\n    color: rgba(48, 57, 74, 1);\n}\n\n.label{\n    color: rgba(123, 144, 182, 1);\n}\n\n.session-header{\n    color: #042F53;\n}\n\n.profile-number{\n    color: #78BEE3;\n    font-size: 25px;\n    font-weight: 700;\n}\n\n.profile-text{\n    color: #7591A3;\n}\n\n\n.outline-container {\n    background: radial-gradient(100.6% 317.94% at 0% 0%, rgba(100, 179, 244, 0.2) 0%, rgba(194, 229, 156, 0.1) 14.32%, rgba(100, 179, 244, 0.11) 32.75%, rgba(194, 229, 156, 0.06) 57.1%, rgba(100, 179, 244, 0.12) 76.19%, rgba(194, 229, 156, 0.12) 100%) \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
