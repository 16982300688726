// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Recommended Content */
.essay-container {
    background: radial-gradient(100.6% 317.94% at 0% 0%, rgba(100, 179, 244, 0.2) 0%, rgba(194, 229, 156, 0.1) 14.32%, rgba(100, 179, 244, 0.11) 32.75%, rgba(194, 229, 156, 0.06) 57.1%, rgba(100, 179, 244, 0.12) 76.19%, rgba(194, 229, 156, 0.12) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}`, "",{"version":3,"sources":["webpack://./src/brainstorm/Essay.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,2VAA2V;AAC/V","sourcesContent":["/* Recommended Content */\n.essay-container {\n    background: radial-gradient(100.6% 317.94% at 0% 0%, rgba(100, 179, 244, 0.2) 0%, rgba(194, 229, 156, 0.1) 14.32%, rgba(100, 179, 244, 0.11) 32.75%, rgba(194, 229, 156, 0.06) 57.1%, rgba(100, 179, 244, 0.12) 76.19%, rgba(194, 229, 156, 0.12) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
