import React, { useState, useEffect } from 'react';
import '../EditProfile.css';
import edit_2 from '../../icons/profile/edit_2.png';
import { get_intended_major, add_intended_major, add_interested_research } from '../../request/profile/intended_major_api';

const IntendedMajor = () => {
    const [savedInterestedField, setSavedInterestedField] = useState("Architectural Technology / Technician"); 
    const [savedCareerGoals, setSavedCareerGoals] = useState([]); 
    const [newGoal, setNewGoal] = useState(""); 
    const [selectedCareerGoals, setSelectedCareerGoals] = useState(savedCareerGoals);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedInterestedField, setSelectedInterestedField] = useState(savedInterestedField); 
    const [isError, setIsError] = useState(false);

    const interestedFieldList = [
        'Architectural Technology / Technician',
        'Clinical / Medical Laboratory Science and Allied Professions',
        'Ethnic, Cultural Minority, and Gender Studies',
        'Materials Science',
        'Legal Research and Advanced Professional Studies (Post-LL.B./J.D.)',
        'Homeland Security, Other',
        'Fine and Studio Art',
        'Library Science / Librarianship',
        'International Relations and Affairs',
        'Computer Engineering, General',
        'Biomathematics and Bioinformatics',
        'Agricultural / Biological Engineering and Bioengineering',
        'Communication and Media Studies',
        'Journalism',
        'Agriculture, General',
        'Agricultural Business and Management',
        'Agricultural Mechanization',
        'Agricultural Production Operations',
        'Agricultural and Food Products Processing',
        'Agricultural and Domestic Animal Services',
        'Applied Horticulture and Horticultural Business Services',
        'International Agriculture',
        'Agricultural Public Services',
        'Animal Sciences',
        'Food Science and Technology',
        'Plant Sciences',
        'Soil Sciences',
        'Agriculture, Agriculture Operations, and Related Sciences, Other',
        'Natural Resources Conservation and Research',
        'Natural Resources Management and Policy',
        'Radio, Television, and Digital Communication',
        'Public Relations, Advertising, and Applied Communication',
        'Communication, Journalism, and Related Programs, Other',
        'Communications Technology / Technician',
        'Audiovisual Communications Technologies / Technicians',
        'Graphic Communications',
        'Biological / Biosystems Engineering',
        'Engineering, Other',
        'Engineering Technology, General',
        'Architectural Engineering Technologies / Technicians',
        'Civil Engineering Technologies / Technicians',
        'Electrical Engineering Technologies / Technicians',
        'Electromechanical Instrumentation and Maintenance Technologies / Technicians',
        'Environmental Control Technologies / Technicians',
        'Fishing and Fisheries Sciences and Management',
        'Forestry',
        'Wildlife and Wildlands Science and Management',
        'Natural Resources and Conservation, Other',
        'Architecture',
        'City / Urban, Community and Regional Planning',
        'Environmental Design',
        'Interior Architecture',
        'Landscape Architecture',
        'Area Studies',
        'Communications Technologies / Technicians and Support Services, Other',
        'Computer and Information Sciences, General',
        'Computer Programming',
        'Data Processing',
        'Information Science / Studies',
        'Computer Systems Analysis'
      ];
      

    const openModal = () => {
        setIsOpen(true);
        setSelectedCareerGoals(savedCareerGoals);
        setSelectedInterestedField(savedInterestedField); 
    };

    const closeModal = () => setIsOpen(false);

    const handleRemoveGoal = (e, career) => {
        e.preventDefault();
        setSelectedCareerGoals(selectedCareerGoals.filter((i) => i !== career));
    };

    const handleAddGoal = (e) => {
        e.preventDefault();
        if (newGoal.trim() !== "" && !selectedCareerGoals.includes(newGoal)) {
            setSelectedCareerGoals([...selectedCareerGoals, newGoal]); 
            setNewGoal(""); 
            setIsError(false);
        } else {
            setIsError(true);
        }
    };

    const handleSubmission = async (event) => {
        event.preventDefault();
        setSavedInterestedField(selectedInterestedField);  
        setSavedCareerGoals(selectedCareerGoals);
        await add_intended_major({ interested_research_field: selectedInterestedField, career_goals: selectedCareerGoals });
        closeModal();
    };

    const handleAddInterestedField = async (e) => {
        setSavedInterestedField(e.target.value);
        await add_interested_research(e.target.value);
    }


    useEffect(() => {
        const fetchData = async () => {
            const newData = await get_intended_major();
            if (newData && newData.intended_major) {
                const data = newData.intended_major;
                setSavedInterestedField(data.interested_research_field);
                setSavedInterestedField(data.interested_research_field);
                setSelectedCareerGoals(data.career_goals);
                setSavedCareerGoals(data.career_goals);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-col space-x-1 bg-cover bg-no-repeat bg-center rounded-[16px] shadow-md p-4 gap-6 session-header bg-[#FFFFFF]">
            <div className="flex flex-row justify-between items-center w-full"> 
                <div className='text-xl mx-4 font-semibold text-[#042F53]'>Intended Major</div>
                <div>             
                    <button 
                        onClick={openModal} 
                        className="text-[#30394A] px-4 py-2 rounded text-sm"
                    >
                        Edit
                        <img src={edit_2} alt="edit" className="w-4 h-4 inline-block ml-2 mb-1" />
                    </button>
                </div>
            </div>

            <div className='flex flex-row gap-3 items-start'>
                <div className='mx-4 space-y-2'>
                    <div className='font-semibold text-[#7591A3] text-sm'>What field are you interested in researching?</div>
                    <div>
                        <select
                            id="interested-field-outside"
                            value={savedInterestedField}  
                            onChange={handleAddInterestedField}
                            className="block w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        >
                            {interestedFieldList.map((field, index) => (
                                <option key={index} value={field}>{field}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className='space-y-2'>
                    <div className='font-semibold text-[#7591A3] text-sm'>Do you have a specific career goal?</div>
                    <p>{savedCareerGoals && savedCareerGoals.length > 0 ? savedCareerGoals.join(", ") : "--"}</p>
                </div>
            </div>

            {isOpen && (
                <Modal
                    selectedInterestedField={selectedInterestedField}
                    setSelectedInterestedField={setSelectedInterestedField}
                    selectedCareerGoals={selectedCareerGoals}
                    setSelectedCareerGoals={setSelectedCareerGoals}
                    newGoal={newGoal}
                    setNewGoal={setNewGoal}
                    handleAddGoal={handleAddGoal}
                    handleRemoveGoal={handleRemoveGoal}
                    handleSubmission={handleSubmission}
                    isError={isError}
                    closeModal={closeModal}
                    interestedFieldList = {interestedFieldList}
                />
            )}
        </div>
    );
};




const Modal = ({ 
    selectedInterestedField, 
    setSelectedInterestedField, 
    selectedCareerGoals, 
    setSelectedCareerGoals, 
    newGoal, 
    setNewGoal, 
    handleAddGoal, 
    handleRemoveGoal, 
    handleSubmission, 
    isError, 
    closeModal,
    interestedFieldList
}) => (

    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Edit Intended Major</h2>
                <div 
                    onClick={closeModal} 
                    className="text-2xl cursor-pointer"
                >
                    &times;
                </div>
            </div>
            <form onSubmit={handleSubmission}>
                <div className="flex flex-col gap-6 mb-4">
                    <div>
                        <label htmlFor="interested-field" className="block text-sm font-medium text-gray-700">What field are you interested in researching?</label>
                        <select
                            id="interested-field"
                            value={selectedInterestedField}
                            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500"
                            onChange={(e) => setSelectedInterestedField(e.target.value)}
                        >
                            {interestedFieldList.map((field, index) => (
                                <option key={index} value={field}>{field}</option>
                            ))}
          
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Do You Have A Specific Career Goal?</label>
                        <div className="flex flex-col">
                            <div className="flex">
                                <input
                                    type="text"
                                    value={newGoal}
                                    onChange={(e) => setNewGoal(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded-l-lg focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Add a career goal"
                                />
                                <button
                                    onClick={handleAddGoal} 
                                    className="bg-[#78BEE3] text-white px-4 rounded-r-lg hover:bg-blue-600"
                                >
                                    Add
                                </button>
                            </div>
                            {isError && 
                                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2" role="alert">
                                    <strong className="font-bold">Oops!</strong>
                                    <div className="block sm:inline">This career goal already exists or is invalid.</div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="space-y-1">
                        <h3 className="text-sm font-medium text-gray-700">Your Career Goals:</h3>
                        <div className={`flex items-start rounded-md border-2 border-dashed border-gray-300 ${(selectedCareerGoals.length == 0) && 'py-4'}`}>
                            {selectedCareerGoals.length > 0 && (
                                <div className="mb-1 w-full">
                                    <ul className="flex flex-wrap gap-2 mt-1 ms-1">
                                        {selectedCareerGoals.map((goal, index) => (
                                            <li key={index} className="relative flex justify-between items-center bg-gray-100 px-4 py-1 mt-0 rounded-lg hover:bg-gray-200">
                                                <div className="text-gray-900">{goal}</div>
                                                <button
                                                    onClick={(e) => handleRemoveGoal(e, goal)}
                                                    className="absolute top-0 right-0 text-red-500 hover:text-red-700 px-2 opacity-0 hover:opacity-100 focus:opacity-100 transition-opacity duration-200"
                                                >
                                                    &times;
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-end">
                    <button 
                        className="bg-[#8FC1D6] text-white px-4 py-2 rounded-md hover:bg-[#8FC1D6] shadow-2xl"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    </div>
);

export default IntendedMajor;
