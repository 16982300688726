import React from "react";

const InputField = ({ label, name, value, onChange, placeholder, type, min, className = '', disabled=false} ) => (
    <div>
      <label className= {"block text-sm font-medium text-gray-700" + className} >{label}</label>
      <input
        type= {type}
        name={name}
        defaultValue={value}
        onChange={onChange}
        className="mt-1 block w-full p-2 border border-gray-300 rounded"
        placeholder={placeholder}
        min = {min}
        disabled = {disabled}
      />
    </div>
  );

export default InputField;


