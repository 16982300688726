// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-box {
    display: none;
    position: absolute;
    z-index: 10;
    background-color: white;
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
    left: 0px; /* Adjust as needed */
    top: 0;  /* Adjust as needed */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.icon-hover:hover + .message-box {
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/billing/Billing.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,SAAS,EAAE,qBAAqB;IAChC,MAAM,GAAG,qBAAqB;IAC9B,qCAAqC;AACzC;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".message-box {\n    display: none;\n    position: absolute;\n    z-index: 10;\n    background-color: white;\n    border: 1px solid #ddd;\n    padding: 8px;\n    border-radius: 4px;\n    left: 0px; /* Adjust as needed */\n    top: 0;  /* Adjust as needed */\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n}\n\n.icon-hover:hover + .message-box {\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
