import React, { useState, useEffect } from 'react';

const MonthYearDropdown = ({ onDateChange, date}) => { // Destructure onDateChange from props

    const [selectedMonth, setSelectedMonth] = useState(date.month);
    const [selectedYear, setSelectedYear] = useState(date.year);

    const currentYear = new Date().getFullYear();
    const years = ["", ...Array.from(new Array(10), (val, index) => currentYear - index)];


    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    useEffect(() => {
        if (selectedMonth && selectedYear) {
            onDateChange({ month: selectedMonth, year: selectedYear });
        }
    }, [selectedMonth, selectedYear]);


    return (
        <div className="flex flex-col">
            <label className="font-semibold mb-2 text-gray-700">When did you take the test?</label>
            <div className = 'flex flex-row gap-2'>
                <select
                    required
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    defaultValue={selectedMonth}
                    checked = {selectedMonth}
                    >
                    <option value="" disabled>Select...</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                </select>

                <select
                    required
                    value = {selectedYear}
                    defaultValue={selectedYear}
                    checked = {selectedYear}
                    onChange={handleYearChange}
                    className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                    {years.map((year) => (
                        <option key={year} value={year} disabled={year === ''}>
                            {year === '' ? 'Select...' : year}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default MonthYearDropdown;

