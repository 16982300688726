import React, { useState, useEffect } from "react";

import BotMessage from "./components/BotMessage";
import UserMessage from "./components/UserMessage";
import Messages from "./components/Messages";
import Input from "./components/Input";

import API from "./ChatbotAPI";

import "./Chatbot.css";
import Header from "./components/Header";

function Chatbot( {uuid, word_limit} ) {
  const word_limit_preset = 800;
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    async function loadWelcomeMessage() {
      setMessages([
        <BotMessage
          key="0"
          fetchMessage={async () => "Hi! I am the BestYou AI Brainstorming assistant. Together, we'll outline your school-specific essay through several effective steps. Please take each question seriously, sharing your deepest insights and experiences. I'm committed to collaborating with you to craft your BestYou Essay. You might want to do some school research during the brainstorming process, please do not close this window. When we've covered enough information I'll prompt you to move on to the next step to focus on the outline.When you're ready, just reply with 'Let's start!'"}
        />
      ]);
    }
    loadWelcomeMessage();
  }, []);
  
  const send = async text => {
    
    const newMessages = messages.concat(
      <UserMessage key={messages.length + 1} text={text} />,
      <BotMessage
        key={messages.length + 2}
        fetchMessage={async () => "I'm thinking..."}
      />
    );

    setMessages(newMessages);

    // 正常从API获取响应
    const botResponse = await API.GetChatbotResponse(uuid, text);

    let botMessageComponent;

    // 判断botResponse是否包含特定字符串
    if (botResponse.includes("Thank you for dedicating your time to the brainstorm session")) {
      // 显示特定的消息
      botMessageComponent = (
        <BotMessage
          key={messages.length + 2}
          fetchMessage={async () => (
            <span>
              Thank you for dedicating your time to the brainstorm session! You can now proceed to the outline by clicking on the progress bar and selecting the <a href={`/outline?uuid=${uuid}`} style={{ color: '#007bff' }}>outline</a> tab.
            </span>
          )}
        />
      );
    } else {
      // 显示正常的botResponse
      botMessageComponent = (
        <BotMessage
          key={messages.length + 2}
          fetchMessage={async () => botResponse}
        />
      );
    }

    const newMessagesFinish = messages.concat(
      <UserMessage key={messages.length + 1} text={text} />,
      botMessageComponent
    );

    setMessages(newMessagesFinish);

  };

  return (
    <div className="chatbot">
      <Header />
      <Messages messages={messages} />
      <Input onSend={send} word_limit={word_limit_preset} />
    </div>
  );
}

export default Chatbot;