import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';


export default function Popup(props) {
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null)

  const mapping = {
    "Fast Mode": "fast_brainstorm",
    "Detailed Mode": "specific_brainstorm",
  }

  const CreateUUID = async () => {
    props.setIsLoading(true);
    props.setError(null);

    console.log(mapping[props.mode]);
    console.log("SELECTED!!" + props.mode);

    try {
        const data = {
            "university": props.university,
            "prompt": props.prompt,
            "mode": mapping[props.mode],
            "bot_type": props.bot_type
        };

        console.log(data);

        const response = await fetch("https://bestucollege.com/api/college_bs/start_brainstorm", {
            method: "POST",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error("HTTP request failed");
        }
        const result = await response.json();
        console.log(result);
        navigate(`/brainstorm?bot_type=${result['bot_type']}&uuid=${result["brainstorm_session_id"]}&word_limit=${props.word_limit}`);
    } catch (err) {
        props.setError(err.message);
    } finally {
        props.setIsLoading(false);
    }
  };

  const handleClick = async () => {
    console.log("Hello world");
    await CreateUUID();
    console.log(props.mode);
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Payment successful
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Thank you for purchase! Your remaining BestYou credits will be {props.credit}. 
                      </p>
                      <p className="text-sm text-gray-500">
                        We'll move to the brainstorming session now.
                      </p>

                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={() => 
                        {props.setOpen(false)
                        handleClick()}
                    }
                  >
                    Start Brainstorming
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm  hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => 
                        props.setOpen(false)
                    }
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
