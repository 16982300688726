import React from 'react';
import './App.css';
import EditorWrapper from './editor/Editor_wrapper';
import Brainstorm from './brainstorm/Brainstorm';
import Dashboard from './dashboard/DashBoard'; 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signin from './signin/Signin';
import Outline from './brainstorm/Outline';
import EditProfile from './profile/EditProfile';
import Essay from './brainstorm/Essay';
import Signup from './signin/Signup';
import BillingWrapper from './billing/BillingWrapper';
import Instruction from './brainstorm/Instruction';
import Agreement from './brainstorm/components/Agreement';
import Details from './history/Details';
import HistoryList from './history/HistoryList';
import Home from './landing/Landing';
import Forgot from './signin/Forgot';
import TokenGenerator from './billing/Token';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import EditingDetails from './history/EditingDetails';
import InstructionEditing from './editor/Instruction';
import DetailExtracurriculars from './profile/DetailExtracurriculars';


function usePageTracking() {
    const location = useLocation();
    useEffect(() => {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    }, [location]);
}

ReactGA.initialize('G-7JWKED7DJG');

function App() {
  usePageTracking();
  const Landing = () => {
    return (<div className="w-full h-screen">
    <iframe
      src="http://localhost:3000"
      frameBorder="0"
      className="w-full h-full"
    ></iframe>
  </div>);
  };

  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/editor" element={<EditorWrapper />} />
        <Route path="/signin" element={<Signin/>}/>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/brainstorm" element={<Brainstorm/>}/>
        <Route path="/outline" element={<Outline/>}/>
        <Route path="/edit-profile" element={<EditProfile/>}/>
        <Route path="/essay" element={<Essay/>}/>
        <Route path="/billing" element={<BillingWrapper/>}/>
        <Route path="/instruction" element={<Instruction/>}/>
        <Route path="/agreement" element={<Agreement/>}/>
        <Route path="/landing" element={<Landing />} />
        <Route path="/brainstorm-details" element={<Details/>}/>
        <Route path="/history" element={<HistoryList/>}/>
        <Route path="/forgot" element={<Forgot/>}/>
        <Route path="/token" element={<TokenGenerator/>}/>
        <Route path="/editing-details" element={<EditingDetails/>}/>
        <Route path="/instruction-editing" element={<InstructionEditing/>}/>
        <Route path= '/profile/detail-extracurriculars' element = {<DetailExtracurriculars/>}/>
      </Routes>
  );
}

export default App;