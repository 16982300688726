import axios from '../index';

export const get_profile_status = async () => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    };
    try {
        const response = await axios.get('profile/status', config);
        return response.data;

    } catch (error) {
        return null;
    }
}