import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import React, { useState, useEffect } from 'react';

const statuses = {
  'Received': 'text-green-700 bg-green-50 ring-green-600/20',
  'Paid': 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}


const transaction_data = [
  {
    id: 1,
    name: 'BestYou Best Value Pack',
    status: 'Received',
    university: '',
    remaining: 38,
    amount: "-2",
    dueDate: 'March 17, 2023',
  },
  {
    id: 2,
    name: 'Brainstorm [Specific] Service',
    status: 'Paid',
    university: 'Harvard University',
    remaining: 40,
    amount: "+20",
    dueDate: 'May 5, 2023',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function BillingHistory() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transaction, SetTransaction] = useState(null);
  const [transaction_data, SetTransactionData] = useState([]);
  var transactionList = [];

  const addTxn = (txn) => {
    const newTxn = {
      name: txn.name
    }
    transactionList = [...transactionList, newTxn];
  };

  const retrieveActivity = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch("https://bestucollege.com/api/billing/retrieve_transaction", {
          method: "GET",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json"
          },
        });

        if (!response.ok) {
            throw new Error("HTTP request failed");
        }

        const result = await response.json();

        SetTransaction(result["transactions"]);

        const transaction_wrapper = [];
        console.log(transaction);

        for (var i = 0; i < result["transactions"].length; i++) {
          var status = result["transactions"][i]["amount"] > 0 ? 'Received' : 'Paid';
          const new_transaction = {
            id: i,
            name: result["transactions"][i]["type"],
            status: status,
            university: result["transactions"][i]["school"],
            remaining: result["transactions"][i]["after_amount"],
            amount: result["transactions"][i]["amount"],
            dueDate: result["transactions"][i]["created_at"],
          }

          transaction_wrapper.push(new_transaction);
        }

        // Reverse the order of the transaction
        transaction_wrapper.reverse();

        SetTransactionData(transaction_wrapper);

        console.log(transaction_wrapper);
        // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
    } catch (err) {
        setError(err.message);
    } finally {
        setIsLoading(false);
    }
  };

  useEffect(() => {
    // Retrieve the activities from backend
    retrieveActivity();
  }, []);

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {transaction_data.map((transaction) => (
        <li key={transaction_data.id} className="flex items-center justify-between gap-x-6 py-5">
          <div className="min-w-0">
            <div className="flex items-start gap-x-3">
              <p className="text-sm font-semibold leading-6 text-gray-900">{transaction.name}</p>
              <p
                className={classNames(
                  statuses[transaction.status],
                  'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                )}
              >
                {transaction.status}
              </p>
            </div>
            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
              <p className="whitespace-nowrap">
                Due on <time>{transaction.dueDate}</time>
              </p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate">{transaction.university}</p>
              <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                <circle cx={1} cy={1} r={1} />
              </svg>
              <p className="truncate">Balance: {transaction.amount}</p>
            </div>
          </div>
          <div className="flex flex-none items-center gap-x-4">
            <p className="truncate">Remaining Credit: {transaction.remaining} </p>
          </div>
        </li>
      ))}
    </ul>
  )
}
