import React from "react";
import { Link, useLocation } from "react-router-dom";
import './Tabs.css'; // Import the CSS for styling

const Tabs = ({ steps }) => {
  const location = useLocation(); // Get the current location to set the active tab

  return (
    <div className="tabs">
      <div className="tab-buttons">
        {steps.map((step) => (
          <Link to={step.href} key={step.id}>
            <button className={location.pathname === step.href ? "active" : ""}>
              {step.name}
            </button>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
