import SideBar from "../sidebar/LightSideBar";
import Bars from "../components/Bars";
import Chatbot from "./chatbot/Chatbot";
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import navigation from "../sidebar/Navigator"
import Hints from "./components/Hints";
import Form from "./components/Form";
import Popup from "./components/Popup";
    

export default function Instruction() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const university = queryParams.get('university'); 
    const prompt = queryParams.get('prompt'); 
    const word_limit = queryParams.get('word_limit');

    const [mode, setMode] = useState("Fast Mode");
    const [botType, setBotType] = useState('Text');
    const [open, setOpen] = useState(false);
    const [costMapping, setCostMapping] = useState({
        "Fast Mode": 2,
        "Detailed Mode": 3
    });

    const handleClick = async () => {
       console.log("Hello world");
       
       if (received - (botType == 'Text' ? paid : paid * 2) < costMapping[mode]) {
           alert("Balance is not enough");
       } else {
           setOpen(true);
           console.log(mode);
       }
    }

    const handleInputTypeChange = (e, type) =>{
        setBotType(type)
        if(type === 'Text'){
            setCostMapping({
                "Fast Mode": 4,
                "Detailed Mode": 6
            })
            return;
        }
        setCostMapping({
            "Fast Mode": 2,
            "Detailed Mode": 3
        })
    }

    const [paid, setPaid] = useState(0);
    const [received, setReceived] = useState(0);

    
    const retrieveBalance = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch("https://bestucollege.com/api/billing/retrieve_balance", {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
          throw new Error("HTTP request failed");
      }

      const result = await response.json();


      setPaid(result["paid"]);
      setReceived(result["received"]);

      console.log(result);

    } catch (err) {
        setError(err.message);
    } finally {
        setIsLoading(false);
    }
    };

    useEffect(() => {
            retrieveBalance();
    }, []);

    const main_content = (
        <main className="bg-white min-h-screen flex flex-col gap-2 flex flex-col items-center">
            <Hints university={university} prompt={prompt}/>
            <div class="px-4  bg-white">
                <div class="flex flex-col gap-4 items-center bg-white">
                    <Popup  word_limit={word_limit} university={university} prompt={prompt} setError={setError} setIsLoading={setIsLoading} mode={mode} open={open} setOpen={setOpen} credit={received - paid - costMapping[mode]} bot_type = {botType}/>
                    <Form setMode={setMode} handleInputTypeChange={handleInputTypeChange}/>
                </div>
            </div>

            <button
                className="flex justify-center rounded-xl px-3 py-1.5 text-sm font-semibold leading-6 text-black hover:bg-grey-200 hover:text-gray-600 transition duration-200"
                onClick={handleClick}
            >
                Next Step
            </button>
        </main>
    )

    return (
        <SideBar main_content={main_content} navigation={navigation}/>
    )
}
