import {
    CalendarIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
} from '@heroicons/react/24/outline'
import HistoryIcon from '../icons/sidebar/history.png';

import brainstormIcon from '../icons/sidebar/brainstorm.png';
import dashboardIcon from '../icons/sidebar/dashboard.png';
import editorIcon from '../icons/sidebar/editor.png';
import historyIcon from '../icons/sidebar/history.png';


const navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: dashboardIcon, current: true },
    { name: 'Profile', href: '/edit-profile', icon: editorIcon, current: false },
    { name: 'Billing', href: '/billing', icon: brainstormIcon, current: false },
    { name: 'History', href: '/history', icon: historyIcon, current: false },
];


// Just export the navigation
export default navigation
