import { React, useEffect, useState } from 'react';
import InputField from '../InputField';
import Modal from './Modal';
import MonthYearDropdown from './MonthYearDropdown';
import { set } from 'react-ga';

const InternationalExamsSession = ({InternationalExamField, hasInternationalExams, area, closeModal, handleSubmit}) => {
  const languageTestSelectedDate = { month: '', year: '' };
  const InternationExamFields = ['Subject', 'Score'];
  const [gradingScale, setGradingScale] = useState(0);
  const [examFields, setExamFields] = useState(InternationalExamField);  
  const [isScoreNotReceived, setIsScoreNotReceived] = useState(false);


const handleCheckboxChange = (e, index) => {
    setExamFields((prev) => {
      const updatedAreaExams = [...prev[area].exams];

      updatedAreaExams[index] = {
        ...updatedAreaExams[index],
        Score: e.target.checked ? 'No Score Received' : ''
      };

      return {
        ...prev, 
        [area]: {
          ...prev[area],
          exams: updatedAreaExams 
        }
      };
    });

    // Optionally update the isScoreNotReceived state if needed
    setIsScoreNotReceived(e.target.checked);
  };

  // Handle adding a new exam section
  const addNewExam = (e) => {
    setExamFields((prev) => ({
      ...prev, 
      [area]: {
        ...prev[area],  
        exams: [
          ...(prev[area]?.exams || []),  
          { Subject: '', Score: '', Date: languageTestSelectedDate }
        ]
      }
    }));
  };


  const removeExam = (index) => {
    setExamFields((prev) => ({
      ...prev, 
      [area]: {
        ...prev[area],  
        exams: prev[area].exams.filter((_, i) => i !== index) 
      }
    }));
  };

const handleInputChange = (e, field, index) => {
    setExamFields((prev) => {
      const updatedAreaExams = [...prev[area].exams]; 
      updatedAreaExams[index] = {
        ...updatedAreaExams[index],  
        [field]: e.target.value     
      };
      return {
        ...prev, 
        [area]: {
          ...prev[area], 
          exams: updatedAreaExams
        }
      };
    });
  };

const handleDateChange = (date, index) =>{
    console.log('check date1111', date);

    setExamFields((prev) => {
        const updatedAreaExams = [...prev[area].exams];
        if (updatedAreaExams[index]?.Date?.month !== date.month || updatedAreaExams[index]?.Date?.year !== date.year) {
            updatedAreaExams[index].Date = date;
            console.log('check updatedAreaExams', updatedAreaExams);
          const updatedFields = {
            ...prev,
            [area]: {
              ...prev[area],
              exams: updatedAreaExams
            }
          };
          console.log('check updatedFields', updatedFields);
          setExamFields(updatedFields);
        }
        return prev; 
      })
      console.log('check date', examFields);
      console.log(examFields);
}




    useEffect(() => {
        setExamFields(InternationalExamField);

        if (InternationalExamField[area] && InternationalExamField[area].length === 0) {
            setExamFields((prev) => ({
                ...prev,
                [area]: {
                    gradeScale: 0,
                    exams: [{ Subject: '', Score: '', Date: languageTestSelectedDate }]
                }
            }));
        }
    }, [InternationalExamField, area]);

    const handleGradeScaleChange = (e) => {
        const newGradeScale = e.target.value;
        setGradingScale(newGradeScale);
        setExamFields((prev) => ({
            ...prev,
            [area]: {
                ...prev[area],  
                gradeScale: newGradeScale
            }
        }));
    };

    const handleExamSubmit = () => {
        if (examFields[area].exams.length === 0) {
          const updatedExamFields = { ...examFields }; 
          delete updatedExamFields[area];
          setExamFields(updatedExamFields);
          handleSubmit(updatedExamFields);
          closeModal();
          return;  
        }
        handleSubmit(examFields);
        closeModal();
    };

  const formContent = (
    <div>
     <div>
     <label>Exams</label>

     <div>
        <label className= {"block text-sm font-medium text-gray-700"}>Grade Scale</label>
                        <input
                        name={'gradingScale'}
                        defaultValue={gradingScale}
                        required
                        onChange={(e) => handleGradeScaleChange(e)}
                        type= 'number'
                        className={"mt-1 block w-full p-2 border border-gray-300 rounded"}
                        />
     </div>

     {examFields[area] &&examFields[area].exams.length > 0 && examFields[area].exams.map((exam, index) => (
        <div key={index} className="mb-4">
        
        {console.log('check index', index)}
        <MonthYearDropdown
            onDateChange={(date) => {
                handleDateChange(date, index);
            }}
        date={exam.Date}
        />
            {InternationExamFields.map((field) => (
            <div>
                <label className= {"block text-sm font-medium text-gray-700" + (field === 'Score' && isScoreNotReceived ? 'bg-gray-200' : '')} >{field}</label>
                    <input
                    key={field}
                    name={`InternationalExam${field}`}
                    value={exam[field]}
                    required
                    onChange={(e) => handleInputChange(e, field, index)}
                    type= {field === 'Score' ? 'number' : 'text'}
                    className={"mt-1 block w-full p-2 border border-gray-300 rounded" + (field === 'Score' && isScoreNotReceived ? 'bg-gray-200' : '' )}
                    disabled={field === 'Score' && isScoreNotReceived}
                    />
            </div>
            ))}

            {/* Checkbox for "Score not received" */}
            <div className="flex items-center mt-2">
            <input
                type="checkbox"
                id={`scoreNotReceived${index}`}
                checked={exam.Score === 'No Score Received'}
                onChange={(e) => handleCheckboxChange(e, index)}
                className="mr-2 h-5 w-5 border-blue-600 border-2 rounded-md focus:ring-blue-600 focus:border-blue-600"
            />
            <label htmlFor={`scoreNotReceived${index}`} className="text-gray-900 text-md font-medium">
                Score not received
            </label>
            </div>

            <button 
            className="text-red-700 border-2 border-red-700 rounded-md px-4 py-1 mt-2 text-start"
            onClick={() => removeExam(index)}>
            Remove
            </button>
    <hr className="my-4" />
  </div>
))}
      <button 
        className="bg-[#8FC1D6] text-white px-4 py-2 rounded-md hover:bg-[#8FC1D6] shadow-2xl"
        onClick={(e)=>addNewExam(e)}
      >
        Add Another Exam
      </button>
     </div>

     <div className="flex justify-end">
                    <button 
                    className="bg-[#8FC1D6] text-white px-4 py-2 rounded-md hover:bg-[#8FC1D6] shadow-2xl"
                        onClick={handleExamSubmit}
                    >
                        Save
                    </button>
       </div>
    </div>
  );

  return (
    hasInternationalExams && (
      <div>
        <Modal identifier={area} modalHandler={() => closeModal()} formContent={formContent} />
      </div>
    )
  );
};

export default InternationalExamsSession;





