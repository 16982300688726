import React from 'react';

 function Mic({ micBackground, mic, isRotating }) {
   return (
     <div className="flex flex-col items-center justify-center ">
         <div className="container">
               <img
                 src={micBackground}
                 className= {isRotating ? "rotating-background" : "static-background"}
                 alt="Background"
               />
               <img
                 src={mic}
                 className="static-foreground"
                 alt="Foreground"
               />
             </div>
       </div>
   );
 }



 export default Mic;