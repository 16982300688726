import React, { useState, useEffect } from 'react';
import InputField from './InputField';
import MonthYearDropdown from './TestScoreComponents/MonthYearDropdown';
import APSession from './TestScoreComponents/APSession';
import StandardTestSession from './TestScoreComponents/StandardTestSession';
import EnglishLanguageSession  from './TestScoreComponents/EnglishLanguageSession';
import RadioButtonGroup from './TestScoreComponents/RatioButtonGroup';
import InternationalExamsSession from './TestScoreComponents/InternationalExamsSession';


const TestScoresForm = ({ onSubmit, closeModal, currentScoreData, completed, field }) => {

    const [isError, setIsError] = useState(false);
    const [hasLanguageTest, setHasLanguageTest] = useState( !!currentScoreData?.English_Language_Proficiency.length > 0 || false);
    const [hasLanguageTestName, setHasLanguageTestName] = useState(currentScoreData?.English_Language_Proficiency.length > 0);
    const [languageTestSelectedDate, setLanguageTestSelectedDate] = useState(currentScoreData?.English_Language_Proficiency[0]?.Date || { month: '', year: '' });
    const [hasLanguageScore, setHasLanguageScore] = useState(currentScoreData?.English_Language_Proficiency.HadLanguageScore || false);
    const [totalLanguageScore, setTotalLanguageScore] = useState(currentScoreData?.English_Language_Proficiency['TotalScore']|| 0);
    const[hasIB, setHasIB] = useState(currentScoreData?.IB || false);
    const [formData, setFormData] = useState({
        SAT: currentScoreData?.SAT,
        ACT: currentScoreData?.ACT,
        PSAT: currentScoreData?.PSAT,
        AP: currentScoreData?.AP || [], 
        English_Language_Proficiency: currentScoreData?.English_Language_Proficiency || [],
        International: currentScoreData?.International || {}, 
    });
     
    //completed copy
    const [testScores, setTestScores] = useState({
        SAT: completed?.SAT || false,
        ACT: completed?.ACT || false,
        PSAT: completed?.PSAT || false,
        AP: completed?.AP || false,
        noScores: (!completed?.SAT && !completed?.ACT && !completed?.PSAT && !completed?.AP) || false,
        English_Language_Proficiency: completed?.English_Language_Proficiency || false,
        International: completed?.International || false,
        IB: completed?.IB || false,
    });

    const handleHadLanguageScore = (value)=>{
        const updatedData = [...formData['English_Language_Proficiency']];
        if (updatedData[0]) {
            updatedData[0]['HadLanguageScore'] = value;
        } else {
            updatedData.push({'HadLanguageScore' : value});
        }
        setFormData((prev) => ({ ...prev, English_Language_Proficiency: updatedData }));
        setHasLanguageScore(value);
        setIsError(false); 
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        console.log(name, checked);

        if (name === 'noScores') {
            setTestScores((prevScores) => ({
                ...prevScores,
                SAT: false,
                ACT: false,
                PSAT: false,
                AP: false,
                noScores: checked,
            }));
            //set all standard test to 0 
            const updatedFormData = { ...formData };
            ['SAT', 'ACT', 'PSAT'].forEach(test => {
                Object.keys(updatedFormData[test]).forEach(key => {
                    updatedFormData[test][key] = 0;
                });
            });
            setFormData(updatedFormData);
            
        } else {
            setTestScores({
                ...testScores,
                [name]: checked,
                noScores: checked ? false : testScores.noScores,
            });
        }
        console.log(testScores);
    };

    const handleAddAPExam = (e) => {
        e.preventDefault();
        setFormData((prev) => ({
            ...prev,
            AP: [...prev.AP, { courseName: '', score: '' }],
        }));
        setIsError(false);
        setTestScores((prev) => ({ ...prev, AP: true }));
    };

    const handleRemoveAPExam = (index) => {
        const updatedAP = formData.AP.filter((_, i) => i !== index);
        setFormData((prev) => ({ ...prev, AP: updatedAP }));
        if (formData.AP.length === 1) {  
            setTestScores((prev) => ({ ...prev, AP: false }));
        }
    };

    const validateForm = () => {
        const selectedTests = ['SAT', 'ACT', 'PSAT', 'AP', 'noScores'];
        const isAnyTestSelected = selectedTests.some((test) => testScores[test]);
        if (!isAnyTestSelected) {
            return false;
        }

        const tests = ['SAT', 'ACT', 'PSAT'];
        tests.forEach((test) => {
            if (testScores[test]) {
                const { math, readingWriting, attempts, reading, english, science } = formData[test];
                const isAttemptValid = attempts && attempts >= 1;
                if (test === 'ACT') {
                    if (!math || !reading || !english || !science || !isAttemptValid) {
                        return false;
                    }
                } else {
                    if (!math || !readingWriting || !isAttemptValid) {
                        return false;
                    }
                }
            }
        });

        formData.AP.forEach((apExam) => {
            if (!apExam.courseName || !apExam.score) {
                return false;
            }
        });

        return !(
            hasLanguageTest && (
              languageTestSelectedDate.month === '' ||  
              languageTestSelectedDate.year === '' )
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const filteredTestScores = Object.fromEntries(
                Object.entries(testScores)
                    .filter(([key, value]) => ['SAT', 'ACT', 'PSAT', 'AP', 'English_Language_Proficiency', 'International','IB'].includes(key)) 
            );
            onSubmit(field, { filteredTestScores, formData });
            setIsError(false);
            closeModal();
        } else {
            setIsError(true);
        }
    };

    const setHasEnglishLanguageTest = (value) => {
        setHasLanguageTest(value);
        if(value === false){
            setFormData((prevFormData) => ({
                ...prevFormData,
                English_Language_Proficiency: []
            }));
        }
    
    };

    const handleInputChange = (e, testType, field, value, index = null) => {
        e.preventDefault()
        if (testType === 'AP' || testType === 'English_Language_Proficiency') {
            const updatedData = [...formData[testType]];
            if(updatedData.length < 1){
                setIsError(true);
                return;
            }
            updatedData[index][field] = value;
            setIsError(false);
            setFormData((prev) => ({ ...prev, [testType]: updatedData }));
        } else {

            setFormData((prevFormData) => ({
                ...prevFormData,
                [testType]: {
                    ...prevFormData[testType],
                    [field]: value,
                },
            })); 
        }
    };

    const InternationalExams = [
        "NCEE (gaokao)",
        "Tawjihi (General Secondary Education Certificate)",
        "Vietnam National High School Graduation Examination",
        "HKDSE Exam (Hong Kong Diploma of Secondary Education)",
        "A-Levels",
        "GCSEs",
        "Certificado de Bachillerato (Baccalaureate Certificate)",
        "Certificado de Conclusão do Ensino Médio (Certificate of Completion of Secondary Education)",
        "Senior Secondary Certificate (SSC)",
        "Surat Tanda Tamat Belajar (STTB)",
        "National Examination Board Examination",
        "Secondary Education Examination (SEE)",
        "Thanawiya (Secondary School Leaving Certificate)",
        "Bachiller"
      ];

      const [hasInternationalExams, setHasInternationalExams] = useState(!!Object.keys(formData?.International).length || false);
      const [selectedInternationExam, setSelectedInternationalExam] = useState(''); 
      const [internationalFormIsOpen, setInternationalFormIsOpen] = useState(false);
      
      const handleSelectedInternationalExam = () => {
        if(selectedInternationExam !== '' ){
            setIsError(false);
            setInternationalFormIsOpen(true)
            if (!formData.International.hasOwnProperty(selectedInternationExam)) {
                setFormData((prev) => ({
                  ...prev,
                  International: {
                    ...prev.International,
                    [selectedInternationExam]: [] 
                  }
                }));
              }
        }else{
            setIsError(true);
        }
      };
      
      const closeAddInternationalExamModel = () => {
            setInternationalFormIsOpen(false);
      }

      const handleInternationalExamSubmit = (data) =>{
            setFormData((prev) => ({
                ...prev,
                International:data
            }))
            //set the user have complete this section
            setHasInternationalExams(!!Object.keys(formData?.International).length || false);
            if ( Object.keys(formData?.International).length > 0){
                setTestScores((prev) => ({ ...prev, International: true }));   
                return; 
            }
            setTestScores((prev) => ({ ...prev, International: false }));   


      }


const testOptions = [
    { id: 'sat', label: 'I have SAT scores', value: 'SAT' },
    { id: 'act', label: 'I have ACT scores', value: 'ACT' },
    { id: 'psat', label: 'I have PSAT scores', value: 'PSAT' },
    { id: 'noScores', label: 'I don’t have any test scores', value: 'noScores' },
  ];

  {console.log('testscore',testScores)}
  {console.log('completed',completed)}

    return (
        <div className="p-6 overflow-y-auto h-[400px] p-4 auto-flow">
  

            <form onSubmit={handleSubmit} className="mt-4 space-y-4">

                {(field === 'Edit' ||  field==='StandardTest') && (
                    <div>

                        <h2 className="text-lg font-bold">Which standardized test scores do you have?</h2>
                            <p className="text-neutral-500">Select all that apply</p>

                                 <StandardTestSession
                                        testScores={testScores}
                                        formData = {formData}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleInputChange = {handleInputChange}
                                />
                    </div>
                )}


                {/* AP input fields */}
                {(field === 'Edit' ||  field==='AP') && (
                        <APSession
                        formData = {formData}
                        handleInputChange = {handleInputChange}
                        handleAddAPExam = {handleAddAPExam}
                        handleRemoveAPExam = {handleRemoveAPExam}
                        />
                )}

                {/* English language proficiency */}

                {(field === 'Edit' ||  field==='English_Language_Proficiency') && (
                            
                        <EnglishLanguageSession
                            formData={formData}
                            setFormData={setFormData}
                            setHasTest={setHasEnglishLanguageTest}
                            hasLanguageTest={hasLanguageTest}
                            hasLanguageTestName={hasLanguageTestName}
                            languageTestSelectedDate = {languageTestSelectedDate}
                            setHasLanguageTestName={setHasLanguageTestName}
                            hasLanguageScore={hasLanguageScore}
                            handleHadLanguageScore={handleHadLanguageScore}
                            handleInputChange={handleInputChange}
                            setTotalLanguageScore={setTotalLanguageScore}
                            totalLanguageScore={totalLanguageScore}
                            setLanguageTestSelectedDate = {setLanguageTestSelectedDate}
                            setTestScores={setTestScores}
                            setIsError = {setIsError}
                        />
                )}

                {/* International Exams */}

                {(field === 'Edit' ||  field ==='International') && 
                            <div>
                                <RadioButtonGroup
                                    label="Do you have any international exams to report?"
                                    name="hasInternationalExams"
                                    options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false }
                                    ]}
                                    selectedValue={hasInternationalExams}
                                    onChange={(value) => {
                                        setHasInternationalExams(value);
                                        //delete all the international exam 
                                        setTestScores((prev) => ({ ...prev, International: value }));
                                        if(!value){
                                            setFormData((prev) => ({
                                                ...prev,
                                                International: {}
                                            }));
                                        }
                                    }}
                                />

                                {/* show out the exit data within international exams  */}
                                <div className = 'flex flex-col gap-20'>
                                    {Object.keys(formData.International).length > 0 &&
                                    Object.keys(formData.International).map((examType) => (
                                        <div key={examType}>
                                        <div>{examType}</div>
                                        <div>Grade scale: {formData.International[examType]?.gradeScale || 'N/A'}</div>
                                        {Array.isArray(formData.International[examType]?.exams) && formData.International[examType].exams.length > 0 ? (
                                            formData.International[examType].exams.map((exam, index) => (
                                            <div key={index}>
                                                <div>
                                                {/* Access the month and year from the Date object */}
                                                Date taken: {exam.Date?.month}/{exam.Date?.year}
                                                </div>
                                                <div>Subject: {exam.Subject}</div>
                                                <div>Score: {exam.Score}</div>
                                            </div>
                                            ))
                                        ) : (
                                            <div>No exams available</div>
                                        )}
                                        </div>
                                    ))
                                    }
                                </div>
                                
                                {hasInternationalExams &&(
                                    <div>
                                        <p>Add exams</p>
                                        <p>You'll be able to enter information about each individual exam in the follow-up questions.</p>

                                        <label className="font-semibold mb-2 text-gray-700">Which exam did you take?</label>
                                        <div className="flex flex-row gap-2">
                                            <select
                                                required
                                                defaultValue={""}  
                                                onChange ={(e)=>{setSelectedInternationalExam(e.target.value)}}              
                                                className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500">
                                                <option value="" disabled>
                                                Select...
                                                </option>
                                                {InternationalExams.map((test) => (
                                                <option key={test} value={test}>
                                                    {test}
                                                </option>
                                                ))}
                                            </select>
                                            <button
                                                className="text-black border-2 border-black rounded-md px-4 py-1 mt-2 text-start"
                                                onClick = {(e)=>{
                                                    e.preventDefault();
                                                    handleSelectedInternationalExam();

                                                }}
                                            >Add Exam</button>
                                        </div>
                                    </div>
                                )}
                                 <InternationalExamsSession InternationalExamField = {formData.International} hasInternationalExams={internationalFormIsOpen}  area={selectedInternationExam} closeModal={closeAddInternationalExamModel} handleSubmit = {handleInternationalExamSubmit}></InternationalExamsSession>  
                            </div>     
                }

                <div>
                    {isError && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md relative" role="alert">
                            <div className="block sm:inline">Please fill out the required fields(e.g. select a test) and ensure they are valid.</div>
                        </div>
                    )}
                </div>


                <div className="flex justify-end">
                    <button 
                        className="bg-[#8FC1D6] text-white px-4 py-2 rounded-md hover:bg-[#8FC1D6] shadow-2xl"
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TestScoresForm;