
import { Container } from './Container'
import backgroundImage from '../images/background-faqs.jpg'

const faqs = [
  [
    {
      question: 'How does the AI consultant improve my college essays?',
      answer:
        'Our AI consultant uses advanced algorithms trained on successful Ivy-league essays to provide personalized, actionable feedback, ensuring your essay resonates with admissions committees.',
    },
    {
      question: 'What does the brainstorming session look like?',
      answer: 'Our brainstorming session comprehensively reviews your activities, guiding you through steps to effectively connect your strengths with what the school offers. Alongside this session, we assist in developing a solid outline and provide an example draft for inspiration, ensuring you can craft a compelling narrative that highlights your unique advantages.      ',
    },
    {
      question: 'Is my information secure with your tool?',
      answer:
        'Absolutely. We prioritize your privacy and security. All personal information and essays are encrypted and securely stored, accessible only to you and our system.        ',
    },
  ],
  [
    {
      question: 'Can I use this tool for applications to universities outside the U.S.?',
      answer:
        'We are currently only support undergrad admissions for Top 60 US schools. However, we’re working to expand our product to assist with essays for universities in the U.S. and abroad, accommodating a variety of application requirements.',
    },
    {
      question:
        'How many college applications can I work on at a time?',
      answer:
      "There's no limit! Our platform is equipped to handle as many applications as you need, allowing you to work on multiple essays simultaneously.",
    },
    {
      question:
        "What if I need help beyond the AI's capabilities?",
      answer:
        'Our platform includes access to peer advisors from top universities for additional guidance and support. You can check out our community tab.        ',
    },
  ],
  [
    {
      question: 'How accurate is the AI feedback?',
      answer:
        "Our AI model is highly accurate, trained on a vast range of successful college essays. It provides score & feedback that's insightful and tailored to your writing style.",
    },
    {
      question: 'How does the payment process work?',
      answer: 'You can purchase BestYou Elite Package. Please add WeChat: dyuan868.',
    },

  ],
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can’t find what you’re looking for, please add our WeChat.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
