import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { TrashIcon } from '@heroicons/react/20/solid'
import ComboList from './ComboList'
import NewProject from './NewProject'
import { useNavigate } from 'react-router-dom';
import { school_data } from './SchoolInfo'
import React, { useEffect, useState } from 'react'
import { retrieve_program, save_program } from '../../request/dashboard_api';
import addIcon from '../../icons/add_icon.png';
import historyIcon from '../../icons/history.png';

const statuses = {
  HIGH: 'text-green-700 bg-green-50 ring-green-600/20',
  MEDIUM: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  LOW: 'text-red-700 bg-red-50 ring-red-600/10',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SchoolList() {
  // Make default load use Side Effect

  const [schoolList, setSchoolList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(false); // For the update on the school list

  // school info that consists with schools that students select
  const [school_data_sel, setSchoolData] = useState([]);

  const navigate = useNavigate();

  const [activity, setActivities] = useState([]);

  const retrieveActivity = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch("https://bestucollege.com/api/user_auth/retrieve-activity", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
        throw new Error("HTTP request failed");
      }

      const result = await response.json();
      console.log(result);

      setActivities(result["activity"]);
      // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBrainstorm = async (university_name) => {
    if (prompt == null) {
      alert("Please select a prompt");
    } else {
      console.log(activity);
      if (activity.length == 0) {
        alert("Please add at least one activity in the profile section")
      } else {
        navigate(`/instruction/?university=${university_name}&prompt=${prompt["name"]}&word_limit=${prompt["word_limit"]}`);
      }
    }
  }

  const handleClickEditor = async (university_name) => {
    if (prompt == null) {
      alert("Please select a prompt");
    } else {
      navigate(`/instruction-editing/?university=${university_name}&prompt=${prompt["name"]}&word_limit=${prompt["word_limit"]}`);
    }
  }

  async function updateSchool(school_list) {
    try {
      const data = {
        "school_list": school_list
      };

      const response = await fetch("https://bestucollege.com/api/dashboard/update_school_list", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      });
      //window.location.reload();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  const deleteSchool = async (university_name) => {
    console.log(schoolList);
    console.log(university_name);
    var newSchoolList = {};
    // Check if the school is in the list
    if (schoolList["school_list"].includes(university_name)) {
      // drop that school from the list
      newSchoolList = schoolList["school_list"].filter((school) => school != university_name);
      await updateSchool(newSchoolList);
    } else {
      alert("We temporarily cannot delete this program. Please try again later.");
    }

    window.location.reload();
  }

  useEffect(() => {
    const fetchSchoolList = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch("https://bestucollege.com/api/dashboard/retrieve_school_list", {
          method: "GET",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token"),
          },
        });

        if (!response.ok) {
          throw new Error("HTTP request failed");
        }

        const result = await response.json();
        setSchoolList(result); // Assuming result is the list you need

        let filteredSchoolData = [];
        let count = 0;

        if ("school_list" in result) {
          for (let i = 0; i < school_data.length; i++) {
            if (result.school_list.includes(school_data[i].name)) {
              const newData = { ...school_data[i], id: count++ };
              filteredSchoolData.push(newData);
            }
          }
        }


        const program_info = await retrieve_program();

        if (program_info != null) {
          for (let i = school_data.length; i < school_data.length + program_info.length; i++) {
            const newData = { ...program_info[i - school_data.length], id: count++ };
            filteredSchoolData.push(newData);
          }
          filteredSchoolData.concat(program_info);
        }

        setSchoolData(filteredSchoolData);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSchoolList();
    retrieveActivity();

  }, []); // The empty array makes this effect run only on mount


  // Chance to get into school; Different chance correspond to different colors

  const ChanceBadge = ({ status, chance }) => {
    return (
      <div
        className={classNames(
          status,
          'rounded-lg py-1 px-2 text-xs font-medium  ring-inset'
        )}
      >
        Chance {chance}%
      </div>
    );
  };

  // Student's progress status

  const ProgressBadge = () => {
    return (
      <div
        className="rounded-md py-1 px-2 text-xs bg-white font-medium ring-inset"
      >
        In Progress
      </div>
    );
  };

  // Card's Menu Option


  return (
    <ul role="list" className="grid grid-cols-1 gap-x-6 gap-y-8 schoolList-md:grid-cols-2 schoolList-lg:grid-cols-3 schoolList-xl:grid-cols-4 xl:gap-x-8">
      {school_data_sel ? school_data_sel.map((client) => (
        <li key={client.id} className="h-72 rounded-[18px] border border-gray-200 bg-white/60 rounded-lg shadow-lg backdrop-blur-sm">

          {/* Top of the school card; the first row has status and action options*/}
          <div className="flex items-center gap-x-4 pt-6 pl-6 pr-6">
            <ChanceBadge status={statuses[client.meta_data.status]} chance={5.92} />
            <ProgressBadge />

            <Menu as="div" className="relative ml-auto">
              {/* Currently, the only option is to delete the school from the list */}
              <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Open options</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </Menu.Button>

              <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 bg-white origin-top-right rounded-md py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={classNames(
                        active ? 'bg-gray-50' : '',
                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                      )}
                      onClick={() => deleteSchool(client.name)}
                    >
                      Delete<span className="sr-only">, {client.name}</span>
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>

          <dl className="p-6">
            <div className="flex justify-between items-center">
              {/* School's icon and name */}
              <div className="flex items-center gap-x-4 pb-3"> {/* Ensures vertical alignment within this div */}
                <img
                  src={client.imageUrl}
                  alt={client.name}
                  className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                />
                <div className="text-sm font-semibold leading-6 text-gray-900">{client.name}</div> {/* Changed font-medium to font-semibold and ensured bold text */}
              </div>
            </div>

            {/* List of prompt */}
            <div className="justify-between gap-x-5 py-1 relative w-full">
              <ComboList people={client.prompts} setNewVariable={setPrompt}  placeholder='Select Prompt'/>
            </div>

            {/* Last row - only has view history and create button */}
            {/* TODO: need to figure out how to select brainstorm / editing mode? Maybe in another page */}
            <div className = 'pt-6'>
              <div className="flex justify-end pt-1">
                <button
                  className="inline-flex  justify-center items-center rounded-full py-1 text-xs font-semibold text-black hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black mx-2 my-2"
                  style={{ color: '#042F53' }}
                  onClick={() => handleBrainstorm(client.name)}
                >
                  {/* <span className="flex items-center"> */}
                    <img src={historyIcon} alt="Add" className="h-6 w-5 mr-2 pb-1" />
                    <p className="mr-2">Brainstorm</p>
                  {/* </span> */}
                </button>

                
              </div>
            </div>

          </dl>
        </li>
      ))
        : null}

      {/* Add new school into the list */}

      <NewProject school_list={schoolList} />

      {/**<NewProject school_list={schoolList}/>**/}
    </ul>
  )
}