/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/


export default function Form(props) {
  const { setMode, handleInputTypeChange } = props;

    return (
      <div className="max-h-96 overflow-y-auto">
         <form action="#" method="POST" className="flex flex-col gap-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 text-center">
              Brainstorming Mode
            </label>
            <select
              id="bs-mode"
              name="bs-mode"
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue="Fast Mode"
              onChange={(e) => {
                  console.log("Selected mode:", e.target.value);
                  console.log(typeof setMode); 
                  setMode(e.target.value);
              }}
              >
                  <option>Fast Mode</option>
                  <option>Detailed Mode</option>
              </select>
        </div>
        {/* <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 text-center">
                Brainstorming Input Type
              </label>
              <select
                id="bs-mode-type"
                name="bs-mode-type"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue="Fast Mode"
                onChange={(e) => {
                    console.log("Selected input type:", e.target.value);
                    handleInputTypeChange(e, e.target.value);
                }}
                >
                    <option>Text</option>
                    <option>Voice</option>
                </select>

              </div> */}
      </form>
      </div>
       
    )
  }
  