import { createSlice } from '@reduxjs/toolkit';

export const actionSlice = createSlice({
    name: 'action',
    initialState: {
    },
    reducers: {
    },
});

export const { someAction } = actionSlice.actions;
export default actionSlice.reducer;
