import React, { useState, useEffect } from 'react';
import nameCardImage from '../../images/namecard.svg';
import nameAvatar from '../../images/avatar.png';
import ProgressBar from '../../components/ProgressBar';
import editIcon from '../../icons/edit-button-2.jpg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {get_profile_status } from '../../request/profile/profile_status_api';
import { Link } from 'react-router-dom';
import essay_status from '../../icons/essay_status.png';

const CardContainer = () => {
    return (
      <div className="flex flex-row space-x-4 h-auto min-h-[120px]">
        <NameCard className="basis-3/4"/>
       {/*  <InfoCard style="bg-white" wBasis={1/4}/> */}
        <ProfileCompletion className="basis-1/4"/>
      </div>
    );
}

// Need to fix the bug of incorrect ratio
// TODO: Need to investigate why the ratio is not work as normal
const NameCard = ({wBasis}) => {
    return (
      <div style={{ backgroundImage: `url(${nameCardImage})` }} className={`bg-cover bg-no-repeat bg-center basis-5/12 rounded-[16px] shadow-md p-4`}>
        <div class="flex flex-row items-center justify-center h-full space-x-4">
            <img src={nameAvatar} alt="Avatar" className="w-16 h-16 rounded-full border-2 border-white object-cover" style={{ aspectRatio: '1 / 1' }}/>
            <div class="basis-1/2">
                <p className="text-lg text-white">Welcome back,</p>
                <p className="text-xl font-semibold text-white">BestU</p>
            </div>
        </div>
      </div>
   );
}

const ProfileCompletion = () => {

    const [status, setStatus] = useState({})
    const [rate, setRate] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            const session_completed = await get_profile_status();
            if (session_completed) {
                setStatus(session_completed.status);

                // Calculate the completion rate directly from session_completed.status
                const totalItems = Object.keys(session_completed.status).length;
                const completedItems = Object.values(session_completed.status).filter(item => item === true).length;
                const completionRate = ((completedItems / totalItems) * 100).toFixed(2);
    
                setRate(completionRate);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="rounded-[16px] shadow-md p-4 bg-white/80 backdrop-blur-lg basis-1/3 flex flex-col items-center space-y-10">
            <div class="flex flex-col space-y-3">
                <div class="flex flex-row justify-center items-center space-x-4">
                        <div className="relative">
                            {/* <ProgressBar/> */}
                            <div style={{ position: 'relative', width: '100px', height: '100px' }}>
                                <svg width="0" height="0">
                                    <defs>
                                    <linearGradient id="gradientColors" x1="0%" y1="0%" x2="100%" y2="100%">
                                        <stop offset="0%" stopColor="rgba(100, 179, 244, 1)" />
                                        <stop offset="100%" stopColor="rgba(194, 229, 156, 1)" />
                                    </linearGradient>
                                    </defs>
                                </svg>

                                <CircularProgressbar
                                    value={rate}
                                    text={`${rate}%`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                    rotation: 0.65,
                                    textSize: '16px',
                                    pathTransitionDuration: 0.5,
                                    pathColor: `url(#gradientColors)`,
                                    trailColor: '#FFFFFF',
                                    textColor: '#042F53',
                                    })}
                                    className="circular-progressbar-text"
                                />
                            </div>
                        </div>
                        <h2 className="text-base font-semibold">Your Profile Creation Status</h2>

                        <div className="mt-0 self-start">
                            <Link to="/edit-profile">
                                <img src={editIcon} alt="Edit" className="w-6 h-6 object-cover" style={{ aspectRatio: '1 / 1' }}/>
                            </Link>
                        </div>
                </div>

                {/* Text Content */}
                <div className="text-left">
                    <p className="text-sm text-gray-600">You Progress <strong> {Object.values(status).filter(item => item === true).length} / {Object.keys(status).length}</strong></p>
                    <p className="text-sm text-gray-600">Academic Records Are Done 🎉</p>
                </div>
            </div>
        </div>
    );
}


const InfoCard = ({ style, wBasis }) => {
    return (
      <div className={`rounded-[16px] bg-white/80 shadow-md p-4 ${style} flex-1 basis-${wBasis}`}>
        {/* Content here will be dynamically added */}
      </div>
    );
}

export default CardContainer;