// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    z-index: 1500;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    max-height: 800px;
    background-color: #fff;
    justify-content: end;
    z-index: 1500;

    border-radius: 13px; /* Adjust as necessary */
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
    
}

.popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}

.close-btn {
    max-width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/editor/components/Popup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,oCAAoC;IACpC,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,sBAAsB;IACtB,oBAAoB;IACpB,aAAa;;IAEb,mBAAmB,EAAE,wBAAwB;IAC7C,0CAA0C;;AAE9C;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".popup {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    background-color: rgba(0, 0, 0, 0.2);\n    display: flex;\n    z-index: 1500;\n    justify-content: center;\n    align-items: center;\n}\n\n.popup-inner {\n    position: relative;\n    padding: 32px;\n    width: 100%;\n    max-width: 1000px;\n    height: 100%;\n    max-height: 800px;\n    background-color: #fff;\n    justify-content: end;\n    z-index: 1500;\n\n    border-radius: 13px; /* Adjust as necessary */\n    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);\n    \n}\n\n.popup-inner .close-btn {\n    position: absolute;\n    top: 16px;\n    right: 16px;\n}\n\n.close-btn {\n    max-width: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
