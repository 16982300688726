// Sidebar.js
import React from 'react';

const Suggestions = ({ title, feedback, setTrigger, item_index, currPos, setCurrPos, onHover, onLeave }) => {
    const clickFunc = () => {
        setTrigger(true);
        setCurrPos(item_index);
    }
    return (
        <div class="flex flex-col  w-[250px] mx-auto bg-white border shadow-sm rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7] mb-4"
            onMouseEnter={() => onHover(item_index)}
            onMouseLeave={onLeave}
        >
            <div class="p-4 md:p-5">
                <h3 class="text-lg font-bold text-gray-800 dark:text-white">
                    {title}
                </h3>
                <p class="mt-2 text-gray-800 dark:text-gray-400">
                    {feedback}
                </p>
                <button onClick={clickFunc} class="mt-3 inline-flex items-center gap-2 mt-5 text-sm font-medium underline text-blue-500 hover:text-blue-700 bg-transparent border-none p-0">
                    Details
                </button>
            </div>
        </div>
    );
};

export default Suggestions;
