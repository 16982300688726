import React from "react";
import "./NoteTaker.css";
import Header from "../chatbot/components/Header";

const TitleWithDot = ({ id, title }) => {
    return (
        <div className="title-with-dot">
            <div className="green-dot">{id}</div>
            <div className="title-text">{title}</div>
        </div>
    );
};

function NoteTaker({ uuid, summary }) {
    // Define an array with your titles
    const steps = [
        { id: 1, title: "Initial Analysis" },
        { id: 2, title: "Deep Analysis" },
        { id: 3, title: "Writing Techniques" },
        { id: 4, title: "Activities" },
        { id: 5, title: "Why School" },
        { id: 6, title: "Outline Correction" },
    ];

    return (
        <div className="chatbot h-full">
            <Header />
            <div className="title-container pt-8 pl-8 pr-8 rl-8">
                {steps.map((step, index) => (
                    <div key={step.id} className="step-container">
                        <TitleWithDot id={step.id} title={step.title} />
                        <div className="subject-text">
                            {index === 0 ? summary : "Waiting for discussion"}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default NoteTaker;
