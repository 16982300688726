import axios from '../index';

export const get_testScore = async () => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    };
    try {
        const response = await axios.get('profile/grades', config);
        console.log('successfully get the test Score!!');
        console.log(response.data);
        return response.data;

    } catch (error) {
        return null;
    }
}

export const handle_testScores = async (data) => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
        }
    };
    try {
        const response = await axios.post('profile/grades', data, config);
        window.location.href = '/edit-profile';
    } catch (error) {
        console.error('Error during add a preference:', error);
    }
}

export const add_testScore = async (data, field) => {
    console.log('check data', data);
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
        }
    };
    try {
        const response = await axios.post(`profile/grades/${field}`, data, config);
        window.location.href = '/edit-profile';
    } catch (error) {
        console.error('Error during add a preference:', error);
    }
}
