import axios from '../index';


export const save_coursework = async (formValues) => {
    const data = {
        gradingScale: formValues.gradingScale,   // Ensure this matches your form values
        gpa: formValues.gpa,
        honorNum: formValues.honourNum,
        apNum: formValues.apNum,
        collegeCourseNum: formValues.collegeCourseNum
    };

    console.log('check data');
    console.log(data);
    
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
        }
    };
    try {
        const response = await axios.post('profile/course-work-summary', data, config);
        // Handle success, maybe show a success notification
        window.location.href = '/edit-profile';
    } catch (error) {
        console.error('Error during saving the coursework summary:', error);
    }
}

export const get_coursework = async () => {
    const config = {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
    };
    try {
        const response = await axios.get('profile/course-work-summary', config);
        // console.log('successfully get the course work summary!!');
        // console.log(response.data);
        return response.data;
        
    } catch (error) {
        return null;
    }
}