import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import LightSideBar from '../sidebar/LightSideBar'
import SideBar from '../sidebar/LightSideBar'
import React, { useState } from 'react';
import { useRef, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useNavigate } from "react-router-dom";
import navigation from '../sidebar/Navigator';
import NameCard from './components/NameCard';
import ProfileCompletion from './components/ProfileCompletion';
import CourseWork from './components/CourseWork';
import Extracurriculars from './components/Extracurriculars';
import IntendedMajor from './components/IntendedMajor';
import PreferenceSetting from './components/PreferenceSetting';
import TestScore from './components/TestScore';
import profile_background from '../images/profile_background.png';

export default function EditProfile() {

  for (var i = 0; i < navigation.length; i++) {
    if (navigation[i]["name"] == "Profile") {
        navigation[i]["current"] = true;
    } else {
        navigation[i]["current"] = false;
    }
  }

  const main_content = (
    <main
      // style={{ backgroundImage: `url(${profile_background})`}}
      
      className={`grid grid-cols-1 md:grid-cols-2 gap-4 px-5 p-7 bg-cover bg-center h-full outline-container`}>
        <NameCard />
        <ProfileCompletion />
        <div className='flex flex-col gap-6 h-full w-full'>
          <CourseWork />
          <Extracurriculars />
          <IntendedMajor />
          <PreferenceSetting />
        </div>
        <div className="h-full w-full">
          <TestScore />
        </div>
    </main>
  )
  
  return (
    <SideBar main_content={main_content} navigation={navigation} />
  )
}
