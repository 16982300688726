import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import ComboList from '../../dashboard/components/ComboList'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { options } from "../../dashboard/components/SchoolInfo";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function EssayList() {
    const [essayList, setEssayList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [current, setCurrent] = useState({
        "name": "All"
    });

    options.push({
        "id": options.length + 1,
        "name": "All"
    })

    const navigate = useNavigate();

    const responseToJSON = (response) => {
        console.log(response);
        var result = [];
        for (var i = 0; i < response["brainstorm_metadata"].length; i++) {
            // If response["brainstorm_meta"] includes the editing_id field, then it is a draft
            if (response["brainstorm_metadata"][i]["editing_id"] != null) {
                result.push({
                    "name": response["brainstorm_metadata"][i]["prompt"],
                    "href": `/editing-details?uuid=${response["brainstorm_metadata"][i]["editing_id"]}`,
                    "university": response["brainstorm_metadata"][i]["university"],
                    "dueDate": response["brainstorm_metadata"][i]["created_at"],
                    "type": response["brainstorm_metadata"][i]["type"],
                });
            } else {
                result.push({
                    "name": response["brainstorm_metadata"][i]["prompt"],
                    "href": `/brainstorm-details?uuid=${response["brainstorm_metadata"][i]["brainstorm_id"]}`,
                    "university": response["brainstorm_metadata"][i]["university"],
                    "dueDate": response["brainstorm_metadata"][i]["created_at"],
                    "type": response["brainstorm_metadata"][i]["type"],
                });
            }
        }

        return result.reverse();
    }

    const retrieveEssay = async () => {
        console.log(current);
        var school;

        setIsLoading(true);
        setError(null);


        if (current !== null) {
            school = current["name"];
        } else {
            school = "All";
        }


        try {
            const data = {
                "university": school
            }
            const response = await fetch("https://bestucollege.com/api/history/retrieve_history", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error("HTTP request failed");
            }

            const result = await response.json();
            console.log(responseToJSON(result));
            setEssayList(responseToJSON(result));
            // navigate(`/brainstorm?uuid=${result["brainstorm_session_id"]}`);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        retrieveEssay();
    }, [current]);

    return (
        <ul role="list" className="divide-y divide-gray-100 space-y-4 min-h-60">
            {essayList.map((project) => (
                <li
                    key={project.id}
                    className="grid grid-cols-12 bg-white/80 gap-x-6 py-4 pl-5 pr-5 rounded-xl shadow-sm items-center"
                >
                    {/* Column 1: Blue dot and Project type */}
                    <div className="col-span-1 flex items-center gap-x-1">
                        <div className="h-2 w-2 bg-blue-500 rounded-full"></div>
                        <div className="text-sm text-gray-900" style={{ color: '#30394A' }}>
                            {project.type}
                        </div>
                    </div>

                    {/* Column 2: University */}
                    <div className="col-span-2">
                        <span className="text-sm font-bold">{project.university}</span>
                    </div>

                    {/* Column 3: Project name and date */}
                    <div className="col-span-5">
                        <p className="text-sm leading-6 text-gray-900">{project.name}</p>
                    </div>

                    {/* Column 4: Project name and date */}

                    <div className="col-span-2">
                        <p className="text-sm leading-6 text-gray-900 font-light">
                            <time dateTime={project.dueDateTime}>{project.dueDate}</time>
                        </p>
                    </div>

                    {/* Column 5: View Button */}
                    <div className="col-span-2 flex justify-end">
                        <a
                            href={project.href}
                            style={{ color: '#042F53' }}
                            className="hidden rounded-md px-2.5 py-1.5 text-sm font-semibold ring-gray-300 hover:bg-gray-50 sm:flex items-center gap-x-2"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-gray-500"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                <circle cx="12" cy="12" r="3" />
                            </svg>
                            View<span className="sr-only">, {project.name}</span>
                        </a>
                    </div>
                </li>
            ))}
        </ul>

    )
}
