import React, { useState, useEffect } from 'react';
import '../EditProfile.css';
import edit_2 from '../../icons/profile/edit_2.png';
import TestScoresForm from './TestScoresForm';  
import StandardTest from './TestScoreComponents/StandardTestSession';
import Modal from './TestScoreComponents/Modal';
import { get_testScore, handle_testScores, add_testScore } from '../../request/profile/test_score_api';

const TestScore = () => {
  const [completed, setCompleted] = useState(
    {
      SAT: false,
      ACT: false,
      PSAT: false,
      AP: false,
      English_Language_Proficiency: false,
      International: false,
      IB: false,
    });
  const [isOpen, setIsOpen] = useState(
  {
    Edit: false,
    StandardTest: false,
    AP: false,
    English_Language_Proficiency: false,
    International: false,
    IB: false,
  }
  );

  //temp: for frontend testing
  const [scoreData, setScoreData] = useState({
    SAT: {
      Math: 0,
      'Reading and Writing': 0,
      Attempts: 0,
    },
    ACT: {
      Math: 0,
      Reading: 0,
      English: 0,
      Science: 0,
      Attempts: 0,
    },
    PSAT: {
      Math: 0,
      readingWriting: 0,
      Attempts: 0,
    },
    AP: [], 
    English_Language_Proficiency: [], 
    International: {}, 
    IB : []
  });

  const openModal = (field) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };

  const closeModal = (field) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [field]: false, 
    }));
  };

  // Save the data from edit form
  const handleFormSubmit = async (field, data) => {
    try {
      const requestData = {
        ...data["formData"], 
        completed: data["filteredTestScores"]
      };
  
      setCompleted(data["filteredTestScores"]);
      setScoreData(data["formData"]);

      console.log("Received data from child:", data);
  
      setIsOpen((prevState) => ({
        ...prevState,
        Edit: false,
      }));
      await handle_testScores(requestData);
      console.log("Test scores updated:", requestData);
      await fetchData();
      
    } catch (error) {
      console.error("Error while submitting test scores:", error);
    }
  };

  const fetchData = async () => {
    try {
      const formData = await get_testScore();
      if (formData) {
        const fetchedData = {
          SAT: formData?.SAT,
          ACT: formData?.ACT,
          PSAT: formData?.PSAT,
          AP: formData?.AP,
          English_Language_Proficiency: formData?.English_Language_Proficiency,
          International: formData?.International,
          IB: formData?.IB,
        };
        setScoreData(fetchedData);
        setCompleted(formData.completed);
        console.log("Completed fetched:", formData.completed);

      }
    } catch (error) {
      console.error("Error fetching test scores:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleModal = (field) => {
    openModal(field);
  };

  return (
    <div className="flex flex-col space-x-1 bg-cover bg-no-repeat bg-center rounded-[16px] shadow-md p-4 gap-8 session-header bg-[#FFFFFF] h-full">
      <div className="mx-4 flex flex-row justify-between items-center w-full">
      <div className='text-xl font-semibold text-[#042F53]'>Test Scores</div>
        <div>
          <button onClick={()=>handleModal('Edit')} className="text-[#30394A] px-4 py-2 rounded text-sm">
            Edit
            <img src={edit_2} alt="edit" className="w-4 h-4 inline-block ml-2 mb-1" />
          </button>
        </div>
      </div>

      <div className="ps-4 mt-4 flex flex-col items-start gap-5">

      {Object.keys(completed)
        .filter(exam => ['SAT', 'ACT', 'PSAT'].includes(exam))
        .map((exam) => {
            const totalScore = Object.keys(scoreData[exam] || {}).reduce((total, key) => {
              if (key !== 'Attempts') {
                const score = parseFloat(scoreData[exam][key]) || 0;
                return total + score;
              }
              return total;
            }, 0);

            return (
              <div key={exam}>
                <div className="text-sm font-semibold">{exam}</div>
                <div>
                  <div className="flex flex-row gap-20 items-center profile-text font-bold justify-between">
                    <div className="text-[#78BEE3] font-[700] text-[20px] md:w-[50px]">
                      {totalScore}
                    </div>
                    <div className="flex flex-row gap-5 ">
                      {Object.keys(scoreData[exam] || {}).map((key) => (
                        key !== 'Attempts' && (
                          <div key={`${exam}-${key}`} className="flex flex-col items-center text-sm">
                            <p>{key}</p>
                            <p>{scoreData[exam]?.[key] || 0}</p>
                          </div>
                        )
                      ))}
                    </div>
                  </div>

                </div>
              </div>
            );
        })}
 
        <div>
          <div className="text-sm font-semibold">AP Exam Scores</div>
          <div>
          {scoreData?.AP.length > 0
              ? scoreData?.AP.map((apExam, index) => (
                  <div key={index} className="flex flex-row profile-text font-bold items-center justify-between text-sm">
                    <p>{apExam.courseName}</p>
                    <p>{apExam.score}</p>
                  </div>
                ))
              : <p className="text-black font-normal">----------</p>}
                      
          </div>
        </div>

        <div className="space-y-3">
          <div className="text-sm font-semibold">English Language Proficiency</div>
          <div className="flex flex-row gap-5 items-center profile-text font-bold text-sm">
            {scoreData.English_Language_Proficiency.length > 0 ? (
              <div>
                <div>
                  {scoreData.English_Language_Proficiency[0]?.test} | {Object.values(scoreData?.English_Language_Proficiency[0]?.Date?.month)} {Object.values(scoreData?.English_Language_Proficiency[0]?.Date?.year)}
                </div>
                <div className="text-[#78BEE3] font-[700] text-[20px]">{scoreData?.English_Language_Proficiency[0]?.TotalScore}</div>
                <div className="flex flex-row gap-5">
                  {scoreData?.English_Language_Proficiency?.length > 0 &&
                    Object.entries(scoreData.English_Language_Proficiency[0])
                      .filter(([key]) => ['Listening', 'Reading', 'Speaking', 'Writing'].includes(key))
                      .map(([key, value]) => (
                        <div key={key} className="text-start">
                          <div className="font-semibold">{key}</div>
                          <div>{value}</div>
                        </div>
                      ))}
                </div>
              </div>
            ) : (
              <p className="text-black text font-normal">----------</p>
            )}
          </div>
        </div>

        <button
                key={'StandardTest'}
                className="text-sm border border-[#042F53] rounded-md px-3 text-[#042F53]"
                onClick={() => handleModal('StandardTest')}
              >
                + Add Standardized Exam
        </button>

        {Object.keys(completed)
        .filter(exam => !['SAT', 'ACT', 'PSAT', 'IB'].includes(exam))
        .map((exam) => {
          return (
            !completed[exam] && (
              <button
                key={exam}
                className="text-sm border border-[#042F53] rounded-md px-3 text-[#042F53]"
                onClick={() => handleModal(exam)}
              >
                + Add {exam.replace(/_/g, ' ')} Exams
              </button>
            
            )
          );
        })}
      </div>

        {/* Modal Rendering */}
        {Object.keys(isOpen).map((key) => {
          return isOpen[key] && (
            <div>
               <Modal identifier={key}  modalHandler={closeModal} formContent={   
                   <TestScoresForm 
                    onSubmit={handleFormSubmit} 
                    closeModal={()=>closeModal(key)}
                    currentScoreData={scoreData}
                    //completed => testscore in testscoreform
                    completed={completed}
                    field={key}
                  />
                }/>
               {/* {console.log("key", key)} */}
            </div>
          );
        })}

    </div>
  );
};
export default TestScore;
