import React from 'react';
import InputField from '../InputField';

const testOptions = [
  { id: 'sat', label: 'I have SAT scores', value: 'SAT' },
  { id: 'act', label: 'I have ACT scores', value: 'ACT' },
  { id: 'psat', label: 'I have PSAT scores', value: 'PSAT' },
  { id: 'noScores', label: 'I don’t have any test scores', value: 'noScores' },
];
const testTypes = ['SAT', 'ACT', 'PSAT'];

const StandardTestSession = ({ testScores,formData, handleInputChange, handleCheckboxChange}) => {

  return (
    <div>
      {testOptions.map((option) => (
                    <div key={option.id} className="flex items-center">
                        <input
                            type="checkbox"
                            id={option.id}
                            name={option.value}
                            checked={testScores[option.value]}  
                            onChange={handleCheckboxChange}
                            className="mr-2"
                        />
                        <label htmlFor={option.id}>{option.label}</label>
                    </div>
                ))}

      {testTypes.map((testType) => (
                                testScores[testType] && (
                                  <div className="mt-4">
                                  <h3 className="font-bold">{testType}</h3>
                                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {Object.keys(formData[testType]).map((field) => (
                                      <InputField
                                        key={field}
                                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                                        name={`${testType}${field}`}
                                        value={formData[testType][field] || ''}
                                        onChange={(e) => handleInputChange(e, testType, field, e.target.value)}
                                        placeholder={`${field.charAt(0).toUpperCase() + field.slice(1)} score`}
                                        type="number"
                                      />
                                    ))}
                                  </div>
                                </div>
                                                                
                      )
                ))}
    </div>
  );
};

export default StandardTestSession;


