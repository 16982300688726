import React from 'react';

const Modal = ({ identifier, modalHandler, formContent}) => {
  return (
//<div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center overflow-y-auto auto-flow h-full" key={identifier}>
<div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center overflow-y-auto" key={identifier}>
      <div className="mt-5 bg-white p-6 rounded-lg shadow-lg w-1/2 h-fix overflow-y-auto auto-flow h-[500px]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">
            {identifier && (identifier === 'Edit' ? 'Edit Scores' : `Edit ${identifier?.replace(/_/g, ' ')} Scores`)}
          </h2>
          <div onClick={() => modalHandler(identifier)} className="text-2xl cursor-pointer">
            &times;
          </div>
        </div>
         {/* Insert the main content right here */}
        {formContent}
      </div>
    </div>
  );
};

export default Modal;